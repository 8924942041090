import React, { useState } from 'react'
import { StyledH2V2 } from '../../../styles/styledComponents'
import ItemForms from '../../itemForms'
import editIcon from '../../../assets/icons/edit.svg'
import { Button, Form } from 'antd'
import { useForm } from 'antd/es/form/Form'
import { toast } from 'react-toastify'
import MeetingsDataService from '../../../services/meetings'

type PropsForms = {
  name: string
  formsTemplateItem: any[]
  id?: string
  url: string
}

const Forms: React.FC<PropsForms> = ({ formsTemplateItem, name, id, url }) => {
  const [isEditing, setIsEditing] = useState(false)
  const [formEdit] = useForm()
  const meetingService = new MeetingsDataService()

  const onFinish = (data: any) => {
    let result: any[] = []
    const newValues = Object.values(data)
    if (formsTemplateItem && formsTemplateItem.length) {
      result = formsTemplateItem.map((val, index) => ({
        name: val.name,
        value: newValues[index]
      }))
    }
    meetingService
      .updateMeetingComponentForm(id ? id : '', result)
      .then((data) => {
        toast.success(`Forms have been updated`, { theme: 'colored' })
        setTimeout(() => {
          window.location.reload()
        }, 1500)
        setIsEditing(false)
      })
      .catch((error) => {
        toast.error(`Forms haven't been updated correctly`, {
          theme: 'colored'
        })
        console.log(error)
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'column'
      }}
    >
      <StyledH2V2>
        {name}{' '}
        <span style={{ cursor: 'pointer' }} onClick={() => setIsEditing(true)}>
          <img
            src={editIcon}
            alt="edit"
            style={{
              margin: '0 10px',
              width: '16px',
              height: '16px'
            }}
          />
        </span>
      </StyledH2V2>
      <Form
        form={formEdit}
        style={{ width: '100%' }}
        onFinish={onFinish}
        autoComplete="off"
      >
        {formsTemplateItem &&
          formsTemplateItem.length > 0 &&
          formsTemplateItem.map((item, index) => {
            return (
              <ItemForms
                result={item}
                key={index}
                index={index + 1}
                isEditing={isEditing}
                form={formEdit}
                url={url}
              />
            )
          })}

        {isEditing && (
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              margin: '1rem 0'
            }}
          >
            <Button
              type="text"
              htmlType="button"
              style={{
                marginRight: '10px',
                border: '1px solid #E0AA25',
                color: '#E0AA25',
                width: '8%'
              }}
              onClick={() => setIsEditing(false)}
              variant="outlined"
            >
              CANCEL
            </Button>
            <Button type="primary" htmlType="submit" style={{ width: '8%' }}>
              SAVE
            </Button>
          </div>
        )}
      </Form>
    </div>
  )
}

export default Forms
