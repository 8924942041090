import React, { useEffect, useContext } from 'react';
import Intercom from '@intercom/messenger-js-sdk';
import JamyContext from '../../context/jamyContext';

const IntercomComponent = () => {
  const { user } = useContext(JamyContext);

  useEffect(() => {
    if (!user?.id) {
      console.error('User information is missing or incomplete.');
      return;
    }

    Intercom({
      app_id: 'dm9bh0yg',
      user_id: user.id,
      name: user.first_name,
      email: user.email,
      created_at: Math.floor(new Date(user.date_joined).getTime() / 1000),
    });

  }, [user]);

  return null;
};

export default IntercomComponent;
