import { Navigate, useLocation } from 'react-router-dom'
import JamyContext from '../../context/jamyContext'
import { useContext } from 'react'

export const UseAuth = () => {
  const isAuthenticated = !!localStorage.getItem('access-token')
  return { isAuthenticated }
}

export const ProtectedRoute = ({ children }: any) => {
  const { isAuthenticated } = UseAuth()
  const location = useLocation()
  return isAuthenticated === true ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  )
}

export const PublicRoute = ({ children }: any) => {
  const { isAuthenticated } = UseAuth()
  const { isAnonymous } = useContext(JamyContext)
  const location = useLocation()
  const redirect = location.search
    ? location.search.split('redirect=')[1]
    : isAnonymous ? '/register' : '/welcome'
  return isAuthenticated === true ? (
    <Navigate to={redirect} replace state={{ path: location.pathname }} />
  ) : (
    children
  )
}
