import { Button, Form, FormInstance, Input, Select, Tag } from 'antd'
import React, { useEffect, useState } from 'react'
import { StyledH2 } from '../../../styles/styledComponents'
import { StyledFolderFormContainer, StyledFolderFormItem, StyledFolderFormItemColorCircle, StyledFolderFormItemColorLabel } from './styledComponents'
import UserDataService from '../../../services/users'
import Loading from '../../loading'
import { toast } from 'react-toastify'

type PropsFolderForm = {
  form: FormInstance
  onFinish: (data: any) => void
  onCancel: () => void
  loading?: boolean
  data?: Folders.ResponseFolder | null
  owner?: string
}

const RenderItem = ({ label, color }: { label: string, color: string }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <StyledFolderFormItemColorCircle color={color} />
      <StyledFolderFormItemColorLabel>{label}</StyledFolderFormItemColorLabel>
    </div>
  )
}

const FolderForm: React.FC<PropsFolderForm> = ({ form, onFinish, onCancel, data, loading, owner }) => {
  const [membersOptions, setMembersOptions] = useState<User.IUserDataResponse[]>([])
  const usersDataService = new UserDataService()
  const [initialValues, setInitialValues] = useState<Folders.Data>({
    members: data?.members || (owner ? [owner] : []),
    name: data?.name || '',
    color: data?.color,
    organization: data?.organization || '',
    owner: data?.owner || '',
  })
  
  useEffect(() => {
    const loadUsers = async () => {
      try {
        const response = await usersDataService.getAllUsers()
        if (response?.data?.results) {
          setMembersOptions(response.data.results)
        }
      } catch (error) {
        toast.error('Error loading members', {theme: 'colored', toastId: 'error-loading-members'})
      }
    }
    
    loadUsers()
  }, [])

  useEffect(() => {
    if (data?.members) {
      form.setFieldValue('members', data.members)
    } else if (owner) {
      form.setFieldValue('members', [owner])
    }
  }, [data, owner])

  return (
    <StyledFolderFormContainer>
      <StyledH2>{data ? 'Edit folder' : 'Create folder'}</StyledH2>
      <Form 
        form={form} 
        onFinish={onFinish}
        layout="vertical"
        initialValues={initialValues}
      >
        <StyledFolderFormItem>
          <Form.Item 
            name="name" 
            rules={[{ required: true, message: 'Please input folder name' }]}
        >
          <Input placeholder="Folder name" />
        </Form.Item>

        <Form.Item 
          name="color"
          rules={[{ required: true, message: 'Please select a color' }]}
        >
          <Select
            placeholder="Select color"
            options={[
              { value: '#0D0711', label: '0D0711' },
              { value: '#0D2626', label: '0D2626' },
              { value: '#0D64F9', label: '0D64F9' },
              { value: '#205856', label: '205856' },
              { value: '#2B7673', label: '2B7673' },
              { value: '#3D0070', label: '3D0070' },
              { value: '#A7469A', label: 'A7469A' },
              { value: '#EF2E52', label: 'EF2E52' },
              { value: '#F27B22', label: 'F27B22' },
              { value: '#F6AE3D', label: 'F6AE3D' },
            ]}
            optionRender={(option) => (
              <RenderItem label={option.label as string} color={option.value as string} />
            )}
            labelRender={(option) => (
              <RenderItem label={option.label as string} color={option.value as string} />
            )}
            defaultValue={initialValues?.color}
            />
          </Form.Item>
        </StyledFolderFormItem>
        <Form.Item 
          name="members"
          rules={[
            { 
              validator: async (_, value) => {
                if (!value?.includes(owner)) {
                  return Promise.reject(new Error('Owner must be a member'))
                }
                return Promise.resolve()
              }
            }
          ]}
        >
          <Select
            mode="multiple"
            placeholder="Select members"
            options={membersOptions.map((member) => ({
              value: member.id,
              label: `${member.first_name || member.email} ${member.last_name || ''}`.trim()
            }))}
            tagRender={(props) => {
              return <Tag
                closable={props.value !== owner}
                onClose={() => {
                  form.setFieldValue('members', form.getFieldValue('members').filter((member: string) => member !== props.value))
                }}
                style={{ 
                  marginInlineEnd: 4,
                  paddingBlock: 4,
                  backgroundColor: '#EEE',
                }}
              >
                {props.label}
              </Tag>
            }}
          />
        </Form.Item>

        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
          <Button onClick={onCancel} type="default" disabled={loading}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit" disabled={loading}>
            {loading ? <Loading /> : data ? 'Update' : 'Create'}
          </Button>
        </div>
      </Form>
    </StyledFolderFormContainer>
  )
}

export default FolderForm 