import { http } from '../../config/http-common'

class FoldersDataService {
  getAllFolders() {
    return http.get<Folders.Response>('/folders/')
  }

  getFolderById(folderId: string, page:number) {
    const params = new URLSearchParams()
    params.append('page', page.toString())
    const url = `/folders/${folderId}/?${params.toString()}`
    return http.get<Folders.ResponseFolder>(url);
  }

  createFolder(data: Folders.Data) {
    return http.post<Folders.Response>('/folders/', data)
  }

  updateFolder(data: Folders.Data) {
    return http.patch<Folders.ResponseFolder>(`/folders/${data.id}/`, data)
  }

  deleteFolder(folderId: string) {
    return http.delete<Folders.ResponseFolder>(`/folders/${folderId}/`)
  }
}

export default FoldersDataService
