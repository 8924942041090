import React, { useContext, useEffect, useState } from 'react'
import {
  StyledMenuContent,
  StyledMenuItemsContent,
  StyledMenuLogo,
  StyledMenuSide
} from './styledComponents'
import logo from '../../assets/logo/logo.png'
import { menuList } from './data'
import { useNavigate } from 'react-router-dom'
import JamyContext from '../../context/jamyContext'

type PropsMenu = {
  handleLogout: () => void
  google_connection: boolean
  slack_connection: boolean
  isValidated: boolean
}

const Menu: React.FC<PropsMenu> = ({
  handleLogout,
  google_connection,
  slack_connection,
  isValidated
}) => {
  const { isAnonymous } = useContext(JamyContext)
  const navigate = useNavigate()

  const navigateToRegister = () => {
    localStorage.removeItem('access-token')
    localStorage.removeItem('is_active')
    navigate('/register')
  }

  return (
    <StyledMenuContent>
      <StyledMenuSide>
        <StyledMenuLogo
          src={logo}
          onClick={() =>
            isAnonymous
              ? navigateToRegister()
              : isValidated
                ? navigate('/calendar')
                : navigate('/')
          }
          style={{ cursor: 'pointer' }}
        />
        <StyledMenuItemsContent hidden={!isValidated}>
          {menuList
            .filter((x) => x.hidden !== true)
            .map((item, index) => {
              return <React.Fragment key={index}>{item.icon}</React.Fragment>
            })}
        </StyledMenuItemsContent>
      </StyledMenuSide>
    </StyledMenuContent>
  )
}

export default Menu
