import styled from 'styled-components'

export const StyledHeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const StyledHeaderButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  padding: 10px;
`

