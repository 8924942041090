import styled from 'styled-components'

export const StyledTemplateContainer = styled.div``
export const StyledTemplateContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 25px;
  margin: 10px;
`
export const StyledTemplatePromptItem = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
`

export const StyledTemplatePromptItemContent = styled.div`
  display: flex;
  align-items: center;
`
export const StyledTemplatePromptItemNumber = styled.div`
  background-color: #e2e7ff;
  padding: 5px;
  color: #5333c1;
  font-weight: bold;
  font-size: 14px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledTemplatePromptItemText = styled.p`
  font-weight: bold;
  font-size: 14px;
  padding-left: 10px;
  margin: 0;
`
export const StyledTemplatePromptItemDeleteImg = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
`
