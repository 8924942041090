import { http } from '../../config/http-common'

class UserDataService {
  create(data: User.ICreateUserData) {
    return http.post<User.ICreateUserResponse>('/users/', data)
  }

  getUser(user_id: string, acccesToken: string) {
    return http.get<User.IUserDataResponse>(`/users/${user_id}/`)
  }

  getAllUsers() {
    return http.get<any>('/users/')
  }

  getAllUsersByPage(page: number) {
    return http.get<any>(`users/?page=${page}`)
  }

  updateUser(data: { is_active: boolean }, userID: string) {
    return http.patch<any>(`/users/${userID}/`, data)
  }

  updateUserType(data: { user_type: string }, userID: string) {
    return http.patch<any>(`/users/${userID}/`, data)
  }

  updateUserActive(data: { is_validated: boolean }, userID: string) {
    return http.patch<any>(`/users/${userID}/`, data)
  }

  updateUserAddNotes(data: { add_notes_check: boolean }, userID: string) {
    return http.patch<any>(`/users/${userID}/`, data)
  }

  updateUserMeetingsSent(
    data: { automatic_notification: boolean },
    userID: string
  ) {
    return http.patch<any>(`/users/${userID}/`, data)
  }

  updateUserNotification(
    data: {
      automatic_notification?: boolean
      send_task_completed?: boolean
      send_tasks_updates?: boolean
      bot_chat_presentation?: string
      bot_chat_presentation_bool?: boolean
    },
    userID: string
  ) {
    return http.patch<any>(`/users/${userID}/`, data)
  }

  updateUserCalendar(
    data: {
      google_calendar_connection?: boolean
      outlook_calendar_connection?: boolean
    },
    userID: string
  ) {
    return http.patch<any>(`/users/${userID}/`, data)
  }

  updateUserWalkthroughs(
    data: {
      templates_walkthrough?: boolean
    },
    userID: string
  ) {
    return http.patch<any>(`/users/${userID}/`, data)
  }

  resendEmailVerification() {
    return http.post<any>(`/authentication/validate/`)
  }
}

export default UserDataService
