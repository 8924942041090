/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState, useEffect } from 'react'
import { StyledH1 } from '../../styles/styledComponents'
import {
  StyledHeaderContent,
  StyledHeaderLinkProfile
} from './styledComponents'
import { Dropdown, MenuProps } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import WebhooksDataService from '../../services/webhooks'
import OrgsDataService from '../../services/org'
import UnlockFeature from '../unlockFeature'
import JamyContext from '../../context/jamyContext'

type PropsHeader = {
  title: string | undefined
  email?: string | null
  logout: () => void
}

const HeaderComponent: React.FC<PropsHeader> = ({ title, email, logout }) => {
  const { permissions } = useContext(JamyContext)
  const webhookDataService = new WebhooksDataService()
  const orgsDataService = new OrgsDataService()
  const [showModalWH, setShowModalWH] = useState(false)
  const [isOrgActive, setIsOrgActive] = useState(false)

  const URL_BILLING = process.env.REACT_APP_BILLING_STRIPE
    ? process.env.REACT_APP_BILLING_STRIPE
    : ''
  
    useEffect(() => {
      orgsDataService
        .getOrganization()
        .then((response) => {
          const activeOrg = response.data.results.find((org) => org.active)
          setIsOrgActive(!!activeOrg)
        })
        .catch((error) => console.error('Error fetching organization data:', error))
    }, [])
  
    const onClicWebhook = () => {
      if (permissions.includes('webhooks')) {
        webhookDataService
          .getWebhookURL()
          .then((response) => {
            window.open(response.data)
          })
          .catch((error) => console.error(error))
      } else {
        setShowModalWH(!showModalWH)
      }
    }
  
    const items: MenuProps['items'] = isOrgActive
    ? [
        {
          key: '1',
          label: (
            <a
              rel="noopener noreferrer"
              href="/profile"
              style={{ width: '100%', display: 'inherit' }}
            >
              Profile
            </a>
          )
        },
        {
          key: '4',
          label: <span onClick={() => onClicWebhook()}>Webhooks</span>
        },
        {
          key: '5',
          label: (
            <a
              rel="noopener noreferrer"
              href="/pricing"
              style={{ width: '100%', display: 'inherit' }}
            >
              Upgrade
            </a>
          )
        },
        {
          key: '2',
          label: (
            <span
              onClick={() => window.open(URL_BILLING)}
              style={{ width: '100%', display: 'inherit' }}
            >
              Billing
            </span>
          )
        },
        {
          key: '3',
          label: (
            <a
              rel="noopener noreferrer"
              href="/welcome"
              style={{ width: '100%', display: 'inherit' }}
            >
              Settings
            </a>
          )
        },
        {
          key: '6',
          label: (
            <span onClick={logout} style={{ width: '100%', display: 'inherit' }}>
              Logout
            </span>
          )
        }
      ]
    : [
        {
          key: '5',
          label: (
            <a
              rel="noopener noreferrer"
              href="/pricing"
              style={{ width: '100%', display: 'inherit' }}
            >
              Subscribe
            </a>
          )
        },
        {
          key: '6',
          label: (
            <span onClick={logout} style={{ width: '100%', display: 'inherit' }}>
              Logout
            </span>
          )
        }
      ]

  return (
    <div>
      <StyledHeaderContent>
        <StyledH1>{title}</StyledH1>
        <Dropdown menu={{ items }}>
          <StyledHeaderLinkProfile>
            <UserOutlined style={{ marginRight: '10px', fontSize: '20px' }} />
            My account
          </StyledHeaderLinkProfile>
        </Dropdown>
      </StyledHeaderContent>
      <UnlockFeature
        namePlan="Premium"
        featureName="webhooks"
        showModal={showModalWH}
        handleShowModal={() => {
          setShowModalWH(!showModalWH)
        }}
        handleUpgrade={() => console.log('upgrade')}
      />
    </div>
  )
}

export default HeaderComponent
