import styled from 'styled-components'

export const StyledFolderFormContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
` 

export const StyledFolderFormItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`

export const StyledFolderFormItemColor = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`

export const StyledFolderFormItemColorCircle = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${({ color }) => color};
`

export const StyledFolderFormItemColorLabel = styled.div`
  font-size: 16px;
  font-weight: 500;
`
