import React, { useContext, useEffect, useState } from 'react'
import ComponentsDataService from '../../services/components'
import { toast } from 'react-toastify'
import { Button, Table, TableProps, Tabs, TabsProps, Tooltip } from 'antd'
import edit from '../../assets/icons/edit.svg'
import { useNavigate } from 'react-router-dom'
import Joyride, { ACTIONS, CallBackProps, EVENTS } from 'react-joyride'
import { steps } from '../../components/walkthroughs/components'
import JamyContext from '../../context/jamyContext'
import { EyeOutlined, PlayCircleFilled } from '@ant-design/icons'
import UserDataService from '../../services/users'
import { validateWalks } from '../../utils/validateWalks'

const ComponentsContainer: React.FC = () => {
  const navigate = useNavigate()
  const { user, getUser } = useContext(JamyContext)
  const componentsDataService = new ComponentsDataService()
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState('1')
  const [tabSelected, setTabSelected] = useState<string>('1')
  const [components, setComponents] = useState<Components.Response>()
  const [runWalk, setRunWalk] = useState(false)
  const [stepIndex] = useState(1)
  const userDataService = new UserDataService()

  useEffect(() => {
    setLoading(true)
    componentsDataService
      .getComponentsByPage(page, tabSelected === '1' ? 'false' : 'true')
      .then((response) => {
        setComponents(response.data)
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
        toast.error('Error getting components', { theme: 'colored' })
        console.error(error)
      })
  }, [page, tabSelected])
  

  useEffect(() => {
    if (user) {
      !user.walkthroughs.templates_walkthrough && setRunWalk(true)
    }
  }, [user])

  const onChangePage = (page: number) => {
    setPage(page.toString())
  }

  const onChange = (key: string) => {
    setTabSelected(key)
  }

  const columns: TableProps<Components.ResultComponent>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      responsive: ['md']
    },
    {
      title: 'Type',
      dataIndex: 'component_type',
      key: 'component_type',
      responsive: ['md']
    },
    {
      title: 'Options',
      dataIndex: 'id',
      key: 'id',
      render: (_, record: any) => {
        const isTheUserAnEditor = record.editors.some(
          (el: any) => el.id === user?.id
        )
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              columnGap: '.55rem'
            }}
          >
            {isTheUserAnEditor ? (
              <img
                src={edit}
                alt="Edit"
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(`/components/create/${record.id}`)}
              />
            ) : null}
            {isTheUserAnEditor ? null : (
              <EyeOutlined
                style={{ fontSize: '1rem', cursor: 'pointer' }}
                onClick={() => navigate(`/components/create/${record.id}`)}
              />
            )}
            <Tooltip placement="bottom" title="Try it">
              <PlayCircleFilled
                style={{ fontSize: '1rem', cursor: 'pointer' }}
                onClick={() => navigate(`/components/tests/${record.id}`)}
              />
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const columns_public: TableProps<Components.ResultComponent>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      responsive: ['md']
    },
    {
      title: 'Owner',
      dataIndex: 'organization_object',
      key: 'organization_object',
      responsive: ['md'],
      render: (organization_object) => organization_object.name
    },
    {
      title: 'Type',
      dataIndex: 'component_type',
      key: 'component_type',
      responsive: ['md']
    },
    {
      title: 'Options',
      dataIndex: 'id',
      key: 'id',
      render: (_, record: any) => {
        const isTheUserAnEditor = record.editors.some(
          (el: any) => el.id === user?.id
        )
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr',
              columnGap: '.55rem'
            }}
          >
            {isTheUserAnEditor ? (
              <img
                onClick={() => navigate(`/components/create/${record.id}`)}
                src={edit}
                alt="Edit"
                style={{ cursor: 'pointer' }}
              />
            ) : null}
            {isTheUserAnEditor ? null : (
              <EyeOutlined
                style={{ fontSize: '1rem', cursor: 'pointer' }}
                onClick={() => navigate(`/components/create/${record.id}`)}
              />
            )}
            <Tooltip placement="bottom" title="Try it">
              <PlayCircleFilled
                style={{ fontSize: '1rem', cursor: 'pointer' }}
                onClick={() => navigate(`/components/tests/${record.id}`)}
              />
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'My components',
      children: (
        <Table
          columns={columns}
          dataSource={
            components
              ? components.results.filter(
                  (x) =>
                    x.owner.id === user?.id ||
                    x.editors.some((editor) => editor.id === user?.id)
                )
              : []
          }
          loading={loading}
          rowKey="id"
          pagination={{
            total: components?.count,
            defaultCurrent: 1,
            defaultPageSize: 10,
            onChange: onChangePage
          }}
        />
      )
    },
    {
      key: '2',
      label: 'Public',
      children: (
        <Table
          columns={columns_public}
          dataSource={
            components
              ? components.results.filter((x) => x.public === true)
              : []
          }
          loading={loading}
          rowKey="id"
          pagination={{
            total: components
              ? components.results.filter((x) => x.public === true).length
              : 0,
            defaultCurrent: 1,
            defaultPageSize: 10,
            onChange: onChangePage
          }}
        />
      )
    }
  ]

  const updateUserWalkValue = () => {
    userDataService
      .updateUserWalkthroughs(
        {
          templates_walkthrough: true
        },
        user ? user.id : ''
      )
      .then((data) => {
        console.log('User updated', data)
        getUser()
      })
      .catch((e) => {
        console.error('Error updating user templates_walkthrough value', e)
      })
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { type, action } = data

    if (action === ACTIONS.CLOSE) {
      localStorage.setItem('second-walk-completed', 'true')
      const isAllWalksCompleted = validateWalks()
      if (isAllWalksCompleted) {
        updateUserWalkValue()
      }
      navigate('/components/create')
    } else if (
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)
    ) {
      updateUserWalkValue()
      setRunWalk(false)
    }
  }

  return (
    <div>
      <Joyride
        run={runWalk}
        steps={steps}
        hideCloseButton
        styles={{ options: { primaryColor: '#E0AA25' } }}
        disableCloseOnEsc
        disableOverlayClose
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
      />
      <div
        style={{
          marginTop: '10px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'end'
        }}
      >
        <Button
          type="primary"
          onClick={() => navigate('/components/create')}
          className="component-button-title"
        >
          CREATE COMPONENT
        </Button>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px'
        }}
      >
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  )
}

export default ComponentsContainer
