const JamyReducer = (state: any, action: any) => {
  switch (action.type) {
    case 'LOGIN':
      return {
        ...state,
        isAuthenticated: true,
        accessToken: action.payload.key,
        userId: action.payload.user_id
      }
    case 'USER':
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload
      }
    case 'RECALL':
      return {
        ...state,
        recall_calendar_auth_token: action.payload
      }
    case 'RECALL_GOOGLE':
      return {
        ...state,
        google_calendar_connection: action.payload
      }
    case 'RECALL_MS':
      return {
        ...state,
        outlook_calendar_connection: action.payload
      }
    case 'ORG':
      return {
        ...state,
        org: action.payload
      }
    case 'GOOGLE_CONNECTION':
      return {
        ...state,
        google_connection: action.payload
      }
    case 'SLACK_CONNECTION':
      return {
        ...state,
        slack_connection: action.payload
      }
    case 'MEET_USER':
      return {
        ...state,
        meetUser: action.payload
      }
    case 'PERMISSIONS':
      return {
        ...state,
        permissions: action.payload
      }
    case 'LOGOUT':
      localStorage.clear()
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        accessToken: null,
        userId: null
      }
    case 'POPULATE':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload
        }
      }
    case 'LOADING':
      return {
        ...state,
        loading: action.payload
      }
    case 'ANONYMOUS':
      return {
        ...state,
        isAnonymous: action.payload
      }
    default:
      throw new Error('Unknown action type')
  }
}

export default JamyReducer
