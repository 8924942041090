import styled from 'styled-components'

interface ITextTask {
  isDone?: boolean
}

export const StyledItemContent = styled.div`
  background-color: white;
  margin: 10px 0;
  padding: 5px;
  min-height: 55px;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid white;
  cursor: pointer;
  width: 100%;

  :hover {
    border: 1px solid #5e6db8;
    background-color: #f5f7ff;
  }

  .ant-checkbox-inner,
  .ant-checkbox-input {
    transform: scale(1.2);
  }
`

export const StyledItemContainter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 80%;
`

export const StyledItemUserContainer = styled.div<{ isAnonymous?: boolean }>`
  display: flex;
  justify-content: ${(props) => (props.isAnonymous ? 'end' : 'start')};
  width: 15%;
`

export const StyledItemIcons = styled.div`
  width: 5%;
  display: flex;
  justify-content: end;
  align-items: center;
`

export const StyledItemIcon = styled.img`
  width: 16px;
  height: 16px;
  margin: 0 5px;
  cursor: pointer;
`
export const StyledItemText = styled.p<ITextTask>`
  margin: 0;
  line-height: 25px;
  text-decoration: ${(props) => (props.isDone ? 'line-through' : 'none')};
`
export const StyledItemPosition = styled.div`
  color: #5333c1;
  background-color: #e2e7ff;
  border-radius: 5px;
  padding: 5px;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 700;
  margin: 0 10px;
`

export const StyledIntegrationsContainer = styled.div`
  display: flex;
  margin-left: 50px;
  margin-top: 5px;
`
export const StyledIntegrationsImg = styled.img`
  width: 20px;
  height: 20px;
`
export const StyledIntegrationBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #eaeaea;
  padding: 5px;
  width: 80px;
  border-radius: 5px;
  margin: 0 5px 0 0;

  p{
    margin: 0 5px;
    font-size: 10px;
    font-weight: bold;
  }
`
