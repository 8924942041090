import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import BannerCalendar from '../../components/bannerCalendar'
import moment from 'moment'
import MeetingsDataService from '../../services/meetings'
import MeetingDayItem from '../../components/meetingDayItem'
import Loading from '../../components/loading'
import { orderBy } from 'lodash'
import CalendarsDataService from '../../services/calendars'
import { toast } from 'react-toastify'
import { Button } from 'antd'
import CreateManualMeetingForm from '../../components/createManualMeetingForm'
import { useForm } from 'antd/es/form/Form'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'

dayjs.extend(customParseFormat)

const MeetingsDayContainer: React.FC = () => {
  const { date } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const [dateText, setDateText] = useState(moment(date).format('DD MMMM YYYY'))
  const [meetings, setMeetings] = useState<Meetings.Results[] | any>()
  const calendarDataService = new CalendarsDataService()
  const meetingService = new MeetingsDataService()
  const [isCreateManualMeetingFormOpen, setIsCreateManualMeetingFormOpen] =
    useState(false)
  const [createManualMeetingForm] = useForm()

  useEffect(() => {
    getMeetingsByDate()
  }, [date])

  const getMeetingsByDate = () => {
    setLoading(true)
    meetingService
      .getAllMeetingsByDate(moment(date).format('DD-MM-YYYY'))
      .then((response) => {
        setMeetings(orderBy(response.data, 'created_date', 'asc'))
        setLoading(false)
      })
      .catch((e) => {
        console.log(e)
        setLoading(false)
      })
  }

  const prev = () => {
    setDateText(moment(dateText).subtract(1, 'days').format('DD MMM YYYY'))
    navigate(
      `/calendar/day/${moment(dateText)
        .subtract(1, 'days')
        .format('YYYY-MM-DD')}`
    )
  }

  const next = () => {
    setDateText(moment(dateText).add(1, 'days').format('DD MMM YYYY'))
    navigate(
      `/calendar/day/${moment(dateText).add(1, 'days').format('YYYY-MM-DD')}`
    )
  }

  const onChangeSwitch = (
    meeting_id?: string,
    override_should_record?: boolean
  ) => {
    setLoading(true)
    if (meeting_id) {
      calendarDataService
        .processMeeting(
          meeting_id,
          override_should_record ? override_should_record : false
        )
        .then(() => {
          setLoading(false)
          getMeetingsByDate()
          toast.success(`Process Meeting updated`, { theme: 'colored' })
        })
        .catch((e) => {
          toast.error('Error to activate process meeting', { theme: 'colored' })
        })
    }
  }

  const onFinishCreateMeetingManual = (data: any) => {
    const payload = {
      invite_link: data['url-meeting'],
      meeting_name: data['meeting-name'],
      start_time: data['meeting-date'].format('YYYY-MM-DDTHH:mm:ss'),
      participants: data['participants'] || []
    }
    meetingService
      .createMeetingManual(payload)
      .then((data) => {
        toast.success(`Meeting have been created!`, { theme: 'colored' })
        createManualMeetingForm.setFieldValue('meeting-date', '')
        createManualMeetingForm.setFieldValue('url-meeting', '')
        createManualMeetingForm.setFieldValue('meeting-name', '')
        createManualMeetingForm.setFieldValue('participants', undefined)
        getMeetingsByDate()
        console.info(data)
        setIsCreateManualMeetingFormOpen(false)
      })
      .catch((err) => {
        if (
          err &&
          err.response &&
          err.response.data &&
          err.response.data.invite_link
        ) {
          toast.error(`${err.response.data.invite_link[0]}`, {
            theme: 'colored'
          })
        } else {
          toast.error(`Error: ${err}`, {
            theme: 'colored'
          })
        }
        console.error(err)
      })
  }

  return (
    <div>
      {dateText && dayjs(dateText) >= dayjs().startOf('day') && !loading && (
        <div>
          <Button
            type="primary"
            onClick={() => setIsCreateManualMeetingFormOpen(true)}
          >
            CREATE MEETING
          </Button>
        </div>
      )}
      <BannerCalendar prev={prev} next={next} month={dateText} />
      {loading ? (
        <Loading />
      ) : (
        <React.Fragment>
          {meetings.map((item: Meetings.Results, index: number) => {
            return (
              <MeetingDayItem
                onChange={onChangeSwitch}
                item={item}
                loading={false}
                key={index}
                status={item.status}
                title={item.name}
                date={moment(item.start_time).format('hh:mm a')}
                summary={`${item.summary?.substring(0, 400)} ....`}
                onClick={() => navigate(`/meetings/details/${item.id}`)}
              />
            )
          })}
          <CreateManualMeetingForm
            isOpen={isCreateManualMeetingFormOpen}
            handleCancel={() => setIsCreateManualMeetingFormOpen(false)}
            onFinish={onFinishCreateMeetingManual}
            formData={createManualMeetingForm}
            dateText={dateText}
          />
        </React.Fragment>
      )}
    </div>
  )
}

export default MeetingsDayContainer
