import { http } from '../../config/http-common'

class ComponentsDataService {
  getAllComponents() {
    return http.get<Components.Response>(`/components/`)
  }

  getComponentsByPage(page: string, publicComponent?: string) {
    return http.get<Components.Response>(
      `/components/?page=${page}${
        publicComponent && `&public=${publicComponent}`
      }`
    )
  }

  getComponentById(component_id: string) {
    return http.get<Components.ResultComponent>(`/components/${component_id}/`)
  }

  updateComponentById(component_id: string, data: Components.ResultComponent) {
    return http.patch<Components.ResultComponent>(
      `/components/${component_id}/`,
      data
    )
  }

  createComponent(data: Components.CreateComponent) {
    return http.post<Components.ResultComponent>(`/components/`, data)
  }

  getTestsComponentById(component_id: string) {
    return http.get<Components.ResultComponent>(
      `/meeting_component_tests/?component_id=${component_id}`
    )
  }

  createMeetingComponentTest(data: any) {
    return http.post<Components.ResultComponent>(
      '/meeting_component_tests/',
      data
    )
  }
}

export default ComponentsDataService
