import { Spin } from 'antd'
import React from 'react'
import { StyledLoadingContent } from './styledComponents'

const Loading: React.FC<{ size?: 'small' | 'large' }> = ({ size = 'large' }) => {
  return (
    <StyledLoadingContent>
      <Spin size={size} />
    </StyledLoadingContent>
  )
}

export default Loading
