import React from 'react';
import HeaderContainer from '../containers/headerContainer';
import MenuContainer from '../components/menuContainer';
import IntercomComponent from '../components/intercom';
import { Container, ContainerContent, Content, Sider } from './styledComponents';

export const LayoutMain = ({ children, title }: any) => {
  return (
    <Container>
      <Sider>
        <MenuContainer />
      </Sider>
      <ContainerContent>
        <HeaderContainer title={title} />
        <Content
          style={{
            backgroundColor: '#F9F9F9',
            minHeight: '90%',
            padding: '2%',
            borderRadius: '15px',
          }}
        >
          {children}
        </Content>
      </ContainerContent>
      <IntercomComponent />
    </Container>
  );
};

