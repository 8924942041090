import styled from "styled-components";

export const StyledMessageBox = styled.div`
  text-align: center;
  font-size: 18px;
  background-color: #975DB8;
  padding: 20px;
  position: relative;
  color: white;
  border-radius: 10px;
  line-height: 1.5;
`