import { ReactNode } from 'react';
import { Step } from 'react-joyride';

const StepTemplateContent: ReactNode = <div style={{ fontWeight: "400", fontSize: "15px", textAlign: "left" }}>
    <p><strong>Form Component</strong></p> 
    <p>Form Component consists of items that can be variables or questions that Jamy will fill out based on the Meeting content. Each item has a name, type and description. This type of Component is specially useful if you want to, for example, gather customer attributes, answer specific questions, fill out dates, choose from specific multiple options, etc.</p> 
    <p><strong>Generation Component</strong></p>
    <p>A Generation Component consists of a set of instructions that Jamy will follow to generate a specific text. You can add as much instructions as you want. For example, the “Summary” Component is a Generation Component.</p>
</div>

export const steps: Step[] = [
    {
        content: <p style={{ color: "#975DB8", fontWeight: "800", fontSize: "15px" }}>Proident sunt eu veniam laborum voluptate voluptate. Esse anim in eu cupidatat culpa incididunt velit. Exercitation eu reprehenderit commodo ad consequat cupidatat eiusmod aliqua velit sit enim. Ex dolor nostrud reprehenderit nostrud aute velit do eu laborum officia.</p>,
        placement: 'center',
        target: 'body',
        styles: { options: {  width: 610 } }
    },
    {
        content: StepTemplateContent,
        locale: { close: <span style={{ padding: "0 2rem" }}>OK</span> },
        placement: 'right',
        target: '.walk-target',
        styles: { options: {  width: 610 } },
        disableBeacon: true
    }    
];
