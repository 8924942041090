import React, { useContext } from 'react'
import HeaderComponent from '../../components/header'
import JamyContext from '../../context/jamyContext'
import HeaderPublicComponent from '../../components/headerPublic'

type PropsHeaderContainer = {
  title: string
}

const HeaderContainer: React.FC<PropsHeaderContainer> = ({ title }) => {
  const { user, logout } = useContext(JamyContext)
  const accessToken = localStorage.getItem('access-token')

  const handleLogout = () => {
    if (accessToken) {
      logout({ accesToken: accessToken })
    }
  }

  if (user) {
    return (
      <HeaderComponent title={title} email={user?.email} logout={handleLogout} />
    )
  }

  return <HeaderPublicComponent title={title} />
}

export default HeaderContainer
