import { Button, Col, Form, FormInstance, Input, Row } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { DeleteOutlined, EditOutlined, LoadingOutlined, SendOutlined, ShareAltOutlined } from '@ant-design/icons'
import { useMeeting } from '../../../../context/meetingContext'
import InputSelectEmails from '../inputEmails'

type PropsRecipients = {
  onClickDelete: (value: string, isTemporal?: boolean) => void
  form: FormInstance
}

const Recipients: React.FC<PropsRecipients> = ({
  onClickDelete,
  form
}) => {
  const { isLoadingUpdateMeeting } = useMeeting()
  const [recipients, setRecipients] = useState<Meetings.Recipients[]>([])
  const [valueEdit, setValueEdit] = useState<string>('')

  useEffect(() => {
    const formRecipients = form.getFieldValue('recipients') || []
    setRecipients(formRecipients)
  }, [form])

  const inputEmailsRef = useRef<{ clearEmails: () => void } | null>(null)
  
  const handleAddRecipient = () => {
    const temporalRecipients = form.getFieldValue('temporalRecipients') || []
    
    // Actualizar tanto el estado local como el valor del formulario
    const newRecipients = [...recipients, ...temporalRecipients]
    setRecipients(newRecipients)
    form.setFieldsValue({
      recipients: newRecipients
    })

    inputEmailsRef.current?.clearEmails()
  }
  const handleEmailsChange = (recipients: Meetings.Recipients[]) => {
    form.setFieldValue('temporalRecipients', recipients);
  };

  const handleDeleteRecipient = (email: string) => {
    const newRecipients = recipients.filter(recipient => recipient.email !== email)
    setRecipients(newRecipients)
    onClickDelete(email, true)
  }
  
  const handleEditRecipient = (email: string) => {
    const newRecipients = recipients.map(recipient => recipient.email === email ? { ...recipient, isEditing: true } : recipient)
    setRecipients(newRecipients)
    form.setFieldValue('recipients', newRecipients)
  }
  
  const editRecipient = (email: string, newEmail: string) => {
    if (!newEmail || recipients.some(recipient => recipient.email === newEmail)) {
      const newRecipients = recipients.map(recipient => recipient.email === email ? { ...recipient, isEditing: false } : recipient)
      setRecipients(newRecipients)
      form.setFieldValue('recipients', newRecipients)
      return
    }
    if (newEmail !== email) {
      const newRecipients = recipients.map(recipient => recipient.email === email ? { ...recipient, email: newEmail, isEditing: false } : recipient)
      const temporalDeleteRecipients = recipients.filter(recipient => recipient.email === email)
      form.setFieldValue('temporalDeleteRecipients', [...form.getFieldValue('temporalDeleteRecipients'), ...temporalDeleteRecipients])
      form.setFieldValue('temporalRecipients', [...form.getFieldValue('temporalRecipients'), { email: newEmail, isEditing: false, first_name: '', last_name: '' }])
      setRecipients(newRecipients)
    }
  }
  
  return (
    <>
      <Row gutter={16}>
        <Col span={22}>
          <Form.Item 
            name="temporalRecipients"
          >
           <InputSelectEmails 
              ref={inputEmailsRef}
              onChange={handleEmailsChange}
              onKeyDown={handleAddRecipient}
           />
          </Form.Item>
        </Col>
        <Col span={2}>
          <Button 
            type="primary" 
            onClick={handleAddRecipient}
          >
            {isLoadingUpdateMeeting ? <LoadingOutlined /> : '+'}
          </Button>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          {recipients?.map((recipient, index) => (
            <div
              key={index}
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                width: '90%',
                height: '40px'
              }}
            >
              {recipient.isEditing ? (
                <Input
                  defaultValue={recipient.email}
                  onBlur={(e) => {
                    const newEmail = e.target.value;
                    editRecipient(recipient.email, newEmail);
                  }}
                  onChange={(e) => {
                    const newEmail = e.target.value;
                    setValueEdit(newEmail)
                  }}
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                      editRecipient(recipient.email, valueEdit)
                    }
                  }}
                  autoFocus
                />
              ) : (
                <span
                  onClick={() => handleEditRecipient(recipient.email)}
                  style={{ 
                    cursor: 'pointer', 
                    height: '40px',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {recipient.email}
                </span>
              )}
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <EditOutlined
                  style={{ margin: '5px', cursor: 'pointer' }}
                  onClick={() => handleEditRecipient(recipient.email)}
                />
                <DeleteOutlined
                  onClick={() => handleDeleteRecipient(recipient.email)}
                  style={{ margin: '5px', cursor: 'pointer' }}
                />
              </div>
            </div>
          ))}
        </Col>
      </Row>
    </>
  )
}

export default Recipients
