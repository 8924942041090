import { http } from '../../config/http-common'

class TemplatesDataService {
  getAllTemplates() {
    return http.get<Templates.ResponseTemplates>(`/templates/`)
  }

  getTemplatesByPage(page: string) {
    return http.get<Templates.ResponseTemplates>(`/templates/?page=${page}`)
  }

  createTemplate(data: any) {
    return http.post<any>(`/meeting_templates/`, data)
  }

  createTemplateV2(data: Templates.CreateTemplate) {
    return http.post<Templates.Results>(`/templates/`, data)
  }

  updateTemplate(data: Templates.CreateTemplate, template_id: string) {
    return http.patch<Templates.Results>(`/templates/${template_id}/`, data)
  }

  getTemplateById(template_id: string) {
    return http.get<Templates.Results>(`/templates/${template_id}/`)
  }
}

export default TemplatesDataService
