/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {
  StyledHeaderButtons,
  StyledHeaderContent,
} from './styledComponents'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import { StyledH1 } from '../../styles/styledComponents'

type PropsHeaderPublic = {
  title: string | undefined
}

const HeaderPublicComponent: React.FC<PropsHeaderPublic> = ({ title }) => {
  const navigate = useNavigate()
  
  const navigateTo = (page: string) => {
    localStorage.removeItem('access-token')
    localStorage.removeItem('is_active')
    navigate(page)
  }

  return (
      <StyledHeaderContent>
          <StyledH1>{title}</StyledH1>
        <StyledHeaderButtons>
          <Button
            type="primary"
            onClick={() => navigateTo('/login')}
          >
            Login
          </Button>
          <Button
            onClick={() => navigateTo('/register')}
          >
            Register
          </Button>
      </StyledHeaderButtons>
    </StyledHeaderContent>
  )
}

export default HeaderPublicComponent
