import { ReactNode } from 'react';
import { Step } from 'react-joyride';

const StepTemplateContent: ReactNode = <div style={{ fontWeight: "400", fontSize: "15px", textAlign: "left" }}>
    <p>Create your first Template to start generating customized Meeting Reports. Remember that before creating a Template, you have to create the Components that you want the Template to use.</p>
</div>

export const steps: Step[] = [
    {
        content: <p style={{ color: "#975DB8", fontWeight: "800", fontSize: "15px" }}>Proident sunt eu veniam laborum voluptate voluptate. Esse anim in eu cupidatat culpa incididunt velit. Exercitation eu reprehenderit commodo ad consequat cupidatat eiusmod aliqua velit sit enim. Ex dolor nostrud reprehenderit nostrud aute velit do eu laborum officia.</p>,
        placement: 'center',
        target: 'body',
        styles: { options: {  width: 610 } }
    },
    {
        content: StepTemplateContent,
        locale: { close: "GO", back: "SKIP" },
        placement: 'bottom-end',
        target: '.template-button-title',
        styles: { options: {  width: 445 } },
        disableBeacon: true
    }
];
