import React, { useContext, useEffect, useState } from 'react'
import Loading from '../../../components/loading'
import Template from '../../../components/template'
import { useForm } from 'antd/es/form/Form'
import ComponentsDataService from '../../../services/components'
import { toast } from 'react-toastify'
import JamyContext from '../../../context/jamyContext'
import TemplatesDataService from '../../../services/templates'
import { AxiosError } from 'axios'
import { useNavigate, useParams } from 'react-router-dom'
import UnlockFeature from '../../../components/unlockFeature'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import { steps } from '../../../components/walkthroughs/templates/create'
import UserDataService from '../../../services/users'
import { validateWalks } from '../../../utils/validateWalks'

const CreateTemplateContainer: React.FC = () => {
  const { user, org, permissions, getUser } = useContext(JamyContext)
  const { id } = useParams()
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [templateEdit, setTemplateEdit] = useState<Templates.Results>()
  const [componentsDefault, setComponentsDefault] = useState<
    Components.ResultComponent[]
  >([])
  const [componentsTemp, setComponentsTemp] = useState<
    Components.ResultComponent[]
  >([])
  const [components, setComponents] = useState<Components.ResultComponent[]>([])
  const componentDataService = new ComponentsDataService()
  const templatesDataService = new TemplatesDataService()
  const [form] = useForm()
  const [runWalk, setRunWalk] = useState(false)
  const [stepIndex, setStepIndex] = useState(0)
  const userDataService = new UserDataService()
  const usersService = new UserDataService()
  const [editors, setEditors] = useState<any[]>([])
  // const [selectedEditors, setSelectedEditors] = useState<any[]>([])

  useEffect(() => {
    getComponentesDefault()

    usersService
      .getAllUsers()
      .then((response) => {
        setEditors(response.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  useEffect(() => {
    if (components) {
      setComponentsTemp(filterComponents(componentsDefault, components))
    }
  }, [components, componentsDefault])

  useEffect(() => {
    if (user) {
      !user.walkthroughs.templates_walkthrough && setRunWalk(true)
    }
  }, [user])

  useEffect(() => {
    if (id) {
      setLoading(true)
      templatesDataService
        .getTemplateById(id)
        .then((response) => {
          setTemplateEdit(response.data)
          form.setFieldsValue({
            name: response.data.name,
            description: response.data.description,
            public: response.data.public,
            tasks_and_topics: response.data.tasks_and_topics,
            editors: response.data.editors
              .map((val) => val.id)
              .filter((val) => val !== user?.id)
          })
          setComponents(response.data.components)
        })
        .catch((error: AxiosError) => {
          console.error(error)
          toast.error('Error getting template', { theme: 'colored' })
        })
    }
  }, [id])

  const getComponentesDefault = () => {
    componentDataService
      .getAllComponents()
      .then((response) => {
        setComponentsDefault(response.data.results)
        setLoading(false)
      })
      .catch((error) => {
        toast.error('Error getting components', { theme: 'colored' })
        console.error(error)
      })
  }

  const onFinish = (data: any) => {
    if (permissions.includes('templates')) {
      setLoading(true)
      const dataRequest: Templates.CreateTemplate = {
        name: data.name,
        description: data.description,
        organization: org ? org.id : '',
        owner_id: user ? user.id : '',
        editors_id: [user ? user.id : '', ...data.editors],
        components_id: components.map((item) => item.id),
        public: data.public ? data.public : false,
        tasks_and_topics: data.tasks_and_topics ? data.tasks_and_topics : false
      }

      if (!templateEdit) {
        templatesDataService
          .createTemplateV2(dataRequest)
          .then(() => {
            form.resetFields()
            setComponents([])
            setLoading(false)
            toast.success('Template created succesfully', { theme: 'colored' })
            navigate('/templates')
          })
          .catch((error: AxiosError) => {
            setLoading(false)
            toast.error(error.message, { theme: 'colored' })
          })
      } else {
        console.log(dataRequest)
        console.log('edta')
        templatesDataService
          .updateTemplate(dataRequest, id ? id : '')
          .then(() => {
            form.resetFields()
            setComponents([])
            setLoading(false)
            toast.success('Template updated succesfully', { theme: 'colored' })
            navigate('/templates')
          })
          .catch((error: AxiosError) => {
            setLoading(false)
            toast.error(error.message, { theme: 'colored' })
          })
      }
    } else {
      setShowModal(true)
    }
  }

  const filterComponents = (
    componentsDefault: Components.ResultComponent[],
    components: Components.ResultComponent[]
  ): Components.ResultComponent[] => {
    const componentIds = new Set(components.map((component) => component.id))
    return componentsDefault.filter(
      (componentDefault) => !componentIds.has(componentDefault.id)
    )
  }

  const updateUserWalkValue = () => {
    userDataService
      .updateUserWalkthroughs(
        {
          templates_walkthrough: true
        },
        user ? user.id : ''
      )
      .then((data) => {
        console.log('User updated', data)
        getUser()
      })
      .catch((e) => {
        console.error('Error updating user templates_walkthrough value', e)
      })
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if ([STATUS.FINISHED].includes(status as any)) {
      localStorage.setItem('fifth-walk-completed', 'true')
      const isAllWalksCompleted = validateWalks()
      if (isAllWalksCompleted) {
        updateUserWalkValue()
      }
      setRunWalk(false)
    } else if ([STATUS.SKIPPED].includes(status as any)) {
      updateUserWalkValue()
      if (action === ACTIONS.CLOSE) {
        setRunWalk(false)
      }
    }
  }

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Joyride
            run={runWalk}
            steps={steps}
            showSkipButton={true}
            hideBackButton={false}
            continuous={true}
            hideCloseButton
            styles={{ options: { primaryColor: '#E0AA25' } }}
            disableCloseOnEsc
            disableOverlayClose
            stepIndex={stepIndex}
            callback={handleJoyrideCallback}
          />
          <Template
            form={form}
            onFinish={onFinish}
            components={components}
            componentsDefault={
              componentsDefault
                ? !templateEdit
                  ? componentsDefault
                  : componentsTemp
                : []
            }
            templateEdit={templateEdit}
            setComponents={setComponents}
            setComponentsDefault={setComponentsDefault}
            editors={editors}
          />
        </div>
      )}
      <UnlockFeature
        namePlan="Premium"
        featureName={'Templates'}
        showModal={showModal}
        handleShowModal={() => {
          setShowModal(!showModal)
        }}
        handleUpgrade={() => console.log('upgrade')}
      />
    </div>
  )
}

export default CreateTemplateContainer
