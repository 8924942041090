import styled from 'styled-components'

export const StyledComponentContainer = styled.div``
export const StyledComponentContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 25px;
  margin: 10px;
`
export const StyledComponentPromptItem = styled.div`
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100%;
`

export const StyledComponentPromptItemContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`
export const StyledComponentPromptItemNumber = styled.div`
  background-color: #e2e7ff;
  padding: 5px;
  color: #5333c1;
  font-weight: bold;
  font-size: 14px;
  width: 25px;
  height: 25px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
`
export const StyledComponentPromptItemText = styled.div`
  font-weight: bold;
  font-size: 14px;
  padding-left: 10px;
  margin: 0;
  width: 100%;
  margin-right: 1rem;
`
export const StyledComponentPromptItemDeleteImg = styled.img`
  width: 15px;
  height: 15px;
  cursor: pointer;
`
export const StyledTestComponentButtonContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0 25px;
  margin: 0 10px 1.25rem;
`