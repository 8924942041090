import { FC, useContext, useEffect, useState } from 'react';
import { Table, Button, Tooltip, Modal } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import FoldersDataService from '../../services/folders';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { StyledFolderIcon } from '../foldersContainer/styledComponents';
import FolderIcon from '../../assets/icons/folder.png'
import SearchBarAutoComplete from '../../components/searchBarAutocomplete';
import { EditOutlined, MinusCircleOutlined } from '@ant-design/icons';
import FolderForm from '../../components/forms/folders';
import { useForm } from 'antd/es/form/Form';
import JamyContext from '../../context/jamyContext';
import MeetingsDataService from '../../services/meetings';
import { toast } from 'react-toastify';
import { StyledFolder, StyledFolderItems, StyledFolderLabels, StyledFolderName, StyledFoldersGrid } from './styledComponents';
import UnlockFeature from '../../components/unlockFeature';

const FolderDetailsContainer: FC = () => {
  const navigate = useNavigate()
  const [folder, setFolder] = useState<Folders.ResponseFolder | null>(null)
  const [childrenFolders, setChildrenFolders] = useState<Folders.ResponseFolder[]>([])
  const [options, setOptions] = useState<any>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false)
  const [meetingIdToRemove, setMeetingIdToRemove] = useState<string | null>(null)
  const [removeFromFolderModal, setRemoveFromFolderModal] = useState(false)
  const [form] = useForm()
  const [createFolderForm] = useForm()
  const [loading, setLoading] = useState(false)
  const [loadingFolder, setLoadingFolder] = useState(true)
  const [loadingRemoveFromFolder, setLoadingRemoveFromFolder] = useState(false)
  const [searchLoading, setSearchLoading] = useState(false)
  const [addMeetingToFolderModal, setAddMeetingToFolderModal] = useState<string | null>(null)
  const [value, setValue] = useState<string | null>(null)
  const [showUnlockFeature, setShowUnlockFeature] = useState(false)
  const foldersService = new FoldersDataService()
  const meetingsService = new MeetingsDataService()
  const { user, permissions } = useContext(JamyContext)
  const { id } = useParams()
  
  const [currentPage, setCurrentPage] = useState(1)
  const [totalMeetings, setTotalMeetings] = useState(0)


  // Get folder by id
  const getFolderById = async (folderId: string) => {
    setLoadingFolder(true)
    const response = await foldersService.getFolderById(folderId, currentPage)
    const folderData = response.data
    setFolder(folderData)
    setTotalMeetings(folderData.meetings?.count || 0)
    setLoadingFolder(false)
    if (folderData.child_folders) setChildrenFolders(folderData.child_folders)
  }

  useEffect(() => {
    if (id) {
      getFolderById(id)
    }
  }, [id, currentPage])
  
  const columns: ColumnsType<Meetings.Results> = [
    {
      title: 'Meeting name',
      dataIndex: 'name',
      key: 'name',
      render: (name: string, row: Meetings.Results) => {
        return (
          <Link 
            to={`/meetings/details/${row.id}`}
            style={{ color: '#000' }}
          >
            {name}
          </Link>
        )
      }
    },
    {
      title: 'Date & Time',
      dataIndex: 'created_date',
      key: 'created_date',
      render: (created_date: string) => {
        return <span>{new Date(created_date).toLocaleString('en-US', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })}</span>
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        return <span>{status.charAt(0).toUpperCase() + status.slice(1)}</span>
      }
    },
    {
      title: 'Options',
      key: 'options',
      render: (row) => (
        <Tooltip title="Remove from folder">
          <Button 
            type="text" 
            shape="circle"
            icon={<MinusCircleOutlined />}
            onClick={() => onHandleRemoveFromFolder(row.id)}
          />
        </Tooltip>
      )
    },
  ];

  // Search for meetings
  const handleSearch = (value: string) => {
    if (permissions && !permissions.includes('folders')) return setShowUnlockFeature(true)
    setSearchLoading(true)
    setValue(value)
    if (value.length > 2) {
      meetingsService
        .getMeetingsSearch(value)
        .then((response) => {
          const meetings = response.data.results
          const options = meetings.map((meeting: Meetings.Results) => ({
            value: meeting.id,
            label: meeting.name
          }))
          setOptions(options)
        })
        .catch((e) => {
          console.log(e)
        })
        .finally(() => {
          setSearchLoading(false)
        })
    }
  }

  // Select a meeting to add to folder
  const onSelect = (value: string) => {
    if (permissions && !permissions.includes('folders')) return setShowUnlockFeature(true)
    setAddMeetingToFolderModal(value)
    setValue(options.find((option: any) => option.value === value)?.label)
  }

  // Add a meeting to folder
  const onAddMeetingToFolder = async () => {
    if (!addMeetingToFolderModal) return
    setLoading(true)
    try {
      await meetingsService.updateMeeting({ folder_id: folder?.id }, addMeetingToFolderModal)
      setAddMeetingToFolderModal(null)
      setValue(null)
      folder?.id && await getFolderById(folder?.id)
    } catch (error) {
      toast.error('Error adding meeting to folder', { theme: 'colored', toastId: 'error-add-meeting-to-folder' })
    } finally {
      setLoading(false)
      
    }
  }
  
  // Open modal to remove a meeting from folder
  const onHandleRemoveFromFolder = (meetingId: string | null) => {
    setMeetingIdToRemove(meetingId)
    setRemoveFromFolderModal(true)
  }

  // Remove a meeting from folder
  const onRemoveFromFolder = async (meetingId: string | null) => {
    if (!meetingId) return
    setLoadingRemoveFromFolder(true)
    await meetingsService.updateMeeting({ folder_id: null }, meetingId)
    setLoadingRemoveFromFolder(false)
    folder?.id && await getFolderById(folder?.id)
    setRemoveFromFolderModal(false)
  }

  // Open modal to edit folder
  const onFinish = (values: Folders.Data) => {
    if (!folder?.id) return
    values.id = folder?.id
    setLoading(true)
    foldersService.updateFolder(values)
      .then((response) => {
        getFolderById(folder?.id)
        setIsModalOpen(false)
      })
      .finally(() => {
        setLoading(false)
      })
  }
  
  // Create a child folder
  const onFinishCreateFolder = (values: Folders.Data) => {
    if (!folder?.id) return
    setLoading(true)
    values.organization = user?.organization || ''
    values.owner = user?.id || ''
    foldersService.createFolder({ ...values, parent_folder: folder?.id })
      .then(() => {
        getFolderById(folder?.id)
        setIsCreateFolderModalOpen(false)
      })
      .catch(() => {
        toast.error('Error creating folder', { theme: 'colored', toastId: 'error-create-folder' })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleCreateFolder = () => {
    if (permissions && !permissions.includes('folders')) return setShowUnlockFeature(true)
    setIsCreateFolderModalOpen(true)
  }

  return (
    <div style={{ padding: '24' }}>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', backgroundColor: 'white', borderRadius: '10px', padding: '24px' }}>
        <div style={{ display: 'flex', alignItems: 'center', flex: 1 }}>
          <StyledFolderIcon src={FolderIcon} />
          <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <EditOutlined 
              style={{ marginRight: '10px', cursor: 'pointer' }}
              onClick={() => setIsModalOpen(true)}
            />
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <h2 style={{ margin: 0 }}>{folder?.name}</h2>
              <div style={{ width: '15px', height: '15px', backgroundColor: `${folder?.color}`, borderRadius: '50%' }}></div>
            </div>
            <span style={{ color: '#666' }}>{`${folder?.meetings?.count || 0} items, ${folder?.members?.length || 0} members`}</span>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flex: 2 }}>
          <SearchBarAutoComplete
            handleSearch={handleSearch}
            onSelect={onSelect}
            options={options}
            placeholder="Add a meeting to this folder"
            value={value}
            loading={searchLoading}
            onCheckPermission={() => {
              if (permissions && !permissions.includes('folders')) return setShowUnlockFeature(true)
            }}
          />
        </div>
      </div>
      <div style={{ 
        marginTop: '24px', 
        padding: '24px', 
        backgroundColor: 'white', 
        borderRadius: '10px',
      }}>
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: '10px'
        }}>
          <StyledFoldersGrid>
            {childrenFolders?.map((folder: Folders.ResponseFolder) => (
              <StyledFolder key={folder.id}
                onClick={() => navigate(`/folders/details/${folder.id}`)}
              >
                <StyledFolderIcon src={FolderIcon} />
                <StyledFolderLabels>
                  <StyledFolderName>{folder.name}</StyledFolderName>
                  <StyledFolderItems>
                    {!!folder.meetings && `${folder.meetings?.count} items`}
                  </StyledFolderItems>
                </StyledFolderLabels>
              </StyledFolder>
            ))}
          </StyledFoldersGrid>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', padding: '10px' }}>
            <Button type="primary" onClick={handleCreateFolder}>
              CREATE FOLDER
            </Button>
          </div>
        </div>
        <hr style={{ border: '1px solid #000' }} />
        <Table
          columns={columns}
          dataSource={folder?.meetings?.results}
          pagination={{
            current: currentPage,
            total: totalMeetings,
            onChange: (page) => {
              setCurrentPage(page)
            },
            showTotal: (total) => `${total} meetings in this folder`
          }}
          rowKey="id"
          loading={loadingFolder}
        />
      </div>
      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
        width={600}
        maskClosable={false}
      >
        <FolderForm
          form={form}
          onFinish={onFinish}
          onCancel={() => setIsModalOpen(false)}
          data={folder}
          loading={loading}
          owner={user?.id}
        />
      </Modal>
      <Modal
        open={isCreateFolderModalOpen}
        onCancel={() => setIsCreateFolderModalOpen(false)}
        footer={null}
        width={600}
        maskClosable={false}
      >
        <FolderForm
          form={createFolderForm} 
          onFinish={onFinishCreateFolder} 
          onCancel={() => setIsCreateFolderModalOpen(false)} 
          owner={user?.id}
        />
      </Modal>
      <Modal
        open={removeFromFolderModal}
        onCancel={() => setRemoveFromFolderModal(false)}
        footer={[
          <Button 
            key="back" 
            onClick={() => setRemoveFromFolderModal(false)}
            disabled={loadingRemoveFromFolder}
          >
            Cancel
          </Button>,
          <Button
            key="remove"
            type="primary"
            onClick={() => onRemoveFromFolder(meetingIdToRemove)}
            disabled={loadingRemoveFromFolder}
          >
            {loadingRemoveFromFolder ? 'Removing...' : 'Remove'}
          </Button>
        ]}
        title="Remove meeting from folder"
        width={600}
      >
        <p>Are you sure you want to remove this meeting from the folder?</p>
      </Modal>
      {/* Modal to add meeting to folder */}
      <Modal
        open={!!addMeetingToFolderModal}
        onCancel={() => setAddMeetingToFolderModal(null)}
        footer={[
          <Button
            key="cancel"
            type="default"
            htmlType="button"
            disabled={loading}
            onClick={() => {
              setAddMeetingToFolderModal(null)
              setValue(null)
            }}
          >
            Cancel
          </Button>,
          <Button
            key="add"
            type="primary"
            htmlType="submit"
            onClick={onAddMeetingToFolder}
            disabled={loading}
          >
            {loading ? 'Adding...' : 'Add'}
          </Button>
        ]}
        width={600}
      >
        <p>Are you sure you want to add this meeting to the folder?</p>
      </Modal>
      <UnlockFeature
        namePlan="Premium"
        featureName="Folders"
        showModal={showUnlockFeature}
        handleShowModal={() => setShowUnlockFeature(!showUnlockFeature)}
        handleUpgrade={() => {}}
      />
    </div>
  );
};

export default FolderDetailsContainer