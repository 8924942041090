import { ReactNode } from 'react'
import { Step } from 'react-joyride'

const StepTemplateContent: ReactNode = (
  <div style={{ fontWeight: '400', fontSize: '15px', textAlign: 'left' }}>
    <p>
      A Template is the blueprint that Jamy will follow to create a Report from
      a Meeting. Templates use Components as their building blocks. A Template
      can have one or more Components.
    </p>

    <p>
      For example, the “Standard Meeting Template” uses only the “Summary”
      Component. You can build Templates with as many Components as you want.
    </p>

    <p>
      Take a look at our Public Templates to get inspired or to use them for
      your specific needs!
    </p>
  </div>
)

const StepComponentContent: ReactNode = (
  <div style={{ fontWeight: '400', fontSize: '15px', textAlign: 'left' }}>
    <p>
      Components are the building blocks of a Template. Each Component will be a
      section in a Meeting Report.
    </p>

    <p>
      For example, the Summary section in a Meeting Report comes from the
      “Summary” Component.
    </p>

    <p>
      Take a look at our Public Components to get inspired or to use them to
      build your own Templates!
    </p>
  </div>
)

export const steps: Step[] = [
  {
    content: (
      <p style={{ color: '#975DB8', fontWeight: '800', fontSize: '15px' }}>
        This functionality will allow you to create custom meeting reports based
        on your specific goals. Whether you are having a sales call, candidate
        interview or internal call, for example.
      </p>
    ),
    locale: {
      skip: (
        <strong aria-label="skip" style={{ color: '#E0AA25' }}>
          Skip
        </strong>
      ),
      next: 'Start'
    },
    placement: 'center',
    target: 'body',
    title: (
      <h2 style={{ fontWeight: '800', fontSize: '40px' }}>
        Let's begin our journey!
      </h2>
    ),
    styles: { options: { width: 610 } }
  },
  {
    target: '.templates-creator',
    content: StepTemplateContent,
    locale: {
      skip: (
        <strong aria-label="skip" style={{ color: '#E0AA25' }}>
          Skip
        </strong>
      ),
      next: 'Next',
      back: 'Back',
      last: 'Last'
    },
    title: (
      <h3 style={{ textAlign: 'left', color: '#5333c1', fontWeight: '800' }}>
        Templates
      </h3>
    ),
    styles: { options: { width: 500 } },
    placement: 'bottom-end'
  },
  {
    target: '.components-creator',
    content: StepComponentContent,
    locale: {
      skip: (
        <strong aria-label="skip" style={{ color: '#E0AA25' }}>
          Skip
        </strong>
      ),
      next: 'Next',
      back: 'Back',
      last: 'Close'
    },
    title: (
      <h3 style={{ textAlign: 'left', color: '#5333c1', fontWeight: '800' }}>
        Components
      </h3>
    ),
    styles: { options: { width: 500 } },
    placement: 'bottom-end'
  }
]
