import React, { useState, useImperativeHandle, useRef } from 'react';
import { Select } from 'antd';

interface InputSelectEmailsProps {
  onChange: (recipients: Meetings.Recipients[]) => void;
  onKeyDown: () => void;
}

const InputSelectEmails = React.forwardRef<{ clearEmails: () => void, }, InputSelectEmailsProps>((props, ref) => {
  const [validEmails, setValidEmails] = useState<string[]>([]);

  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email.trim());
  };

  const handleChange = (values: string[]) => {
    const newValidEmails = values.filter(email => validateEmail(email));
    setValidEmails(newValidEmails);
    const recipients = newValidEmails.map(email => ({
      email: email,
      first_name: '',
      last_name: '',
      isEditing: false
    }));
    props.onChange(recipients);
  };

  const clearEmails = () => {
    setValidEmails([]);
  };

  useImperativeHandle(ref, () => ({
    clearEmails
  }));

  return (
    <Select
      mode="tags"
      style={{ width: '100%' }}
      placeholder="Add new recipients emails separated by commas"
      onChange={handleChange}
      value={validEmails}
      tokenSeparators={[',']}
      open={false}
      suffixIcon={null}
      onKeyDown={e => {
        if (e.key === 'Enter') {
          e.preventDefault()
          props.onKeyDown()
        }
      }}
      tagRender={(props) => {
        const { label, closable, onClose } = props;
        return (
          <span
            style={{
              padding: '2px 8px',
              backgroundColor: '#e6f7ff',
              borderRadius: '4px',
              marginRight: '4px'
            }}
          >
            {label}
            {closable && (
              <span onClick={onClose} style={{ marginLeft: '4px', cursor: 'pointer' }}>
                ×
              </span>
            )}
          </span>
        );
      }}
    />
  );
});

export default InputSelectEmails;

