import { Col, Row } from 'antd'
import { useForm } from 'antd/es/form/Form'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import FooterLinks from '../../components/footerLinks'
import LoginForm from '../../components/forms/login'
import LefSideRegister from '../../components/leftSideRegister'
import Loading from '../../components/loading'
import JamyContext from '../../context/jamyContext'
import { PublicRoute } from '../../hooks/useAuth'

import ILoginUserData from '../../types/auth/login'
import { useSearchParams } from 'react-router-dom'
import MessageBox from '../../components/messageBox'
import MeetingsDataService from '../../services/meetings'

const LoginContainer: React.FC = () => {
  const { login, loading } = useContext(JamyContext)
  const [banner, setBanner] = useState(false)
  const [searchParams] = useSearchParams()
  const [messageBox, setMessageBox] = useState<ReactNode>()
  const meetingDataService = new MeetingsDataService()
  const [formLogin] = useForm()

  const onFinish = (data: ILoginUserData) => {
    data.redirect = searchParams.get('redirect')
    login(data)
    formLogin.resetFields()
  }

  useEffect(() => {
    setBanner(searchParams.get('type') === 'log_in' ? true : false)
    if (searchParams.get('email')) {
      meetingDataService
        .getMeetingBanner(searchParams.get('email'))
        .then((response) => {
          setMessageBox(createMessageBox(response.data))
        })
        .catch((e) => console.log(e))
    }
  }, [])

  const createMessageBox = (response: Meetings.BannerResponse) => {
    let orgUsers = ''
    formLogin.setFieldValue('username', response.email)
    for (let index = 0; index < response.org_users.length; index++) {
      orgUsers =
        orgUsers +
        `${
          index == 0
            ? ' '
            : index > 0 && index < response.org_users.length - 1
            ? ', '
            : ' and '
        }` +
        response.org_users[index]
    }

    return (
      <p style={{ margin: '0' }}>
        <strong>Login</strong> with your account ( {response.email} ) to see the
        full report and <strong>collaborate with {orgUsers}</strong> in the{' '}
        <strong>{response.organization_name}</strong> team in Jamy.
      </p>
    )
  }

  return (
    <React.Fragment>
      <PublicRoute>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12}>
            <LefSideRegister />
            <FooterLinks />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12}>
            {/* TODO: Remove MessageBox if not needed */}
            <MessageBox messageBox={messageBox} />
            {!loading ? (
              <LoginForm form={formLogin} onFinish={onFinish} />
            ) : (
              <Loading />
            )}
          </Col>
        </Row>
      </PublicRoute>
    </React.Fragment>
  )
}

export default LoginContainer
