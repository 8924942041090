
import { Button, Form, Modal, Popover, Select } from 'antd'
import { StyledFolderFormContainer } from '../styledComponents'
import { useForm } from 'antd/es/form/Form';
import { useContext, useEffect, useState } from 'react';
import FoldersDataService from '../../../../services/folders';
import { StyledFolderFormItemColorCircle, StyledFolderFormItemColorLabel } from '../styledComponents';
import MeetingsDataService from '../../../../services/meetings';
import { toast } from 'react-toastify';
import Loading from '../../../loading';
import { useMeetingActions } from '../../../../hooks/useMeetingActions';
import { useMeeting } from '../../../../context/meetingContext';
import JamyContext from '../../../../context/jamyContext';
import UnlockFeature from '../../../unlockFeature';

/**
 * @description Component to add a meeting to an existing folder or remove it from a folder
 * @component AddToFolder
 * @returns {JSX.Element} Form to add/remove meeting from folder
 */


interface AddToFolderProps {
  folder: Folders.Data | null
}

const RenderItem = ({ label, color }: { label: string, color: string }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
      <StyledFolderFormItemColorCircle color={color} />
      <StyledFolderFormItemColorLabel>{label}</StyledFolderFormItemColorLabel>
    </div>
  )
}

const AddToFolder = () => {
  const [form] = useForm()
  const foldersService = new FoldersDataService()
  const [folders, setFolders] = useState<Folders.Data[]>([])
  const meetingsService = new MeetingsDataService()
  const [loading, setLoading] = useState(false)
  const [loadingFolders, setLoadingFolders] = useState(false)
  const [showModalAddToFolder, setShowModalAddToFolder] = useState(false)
  const [removeFromFolderModal, setRemoveFromFolderModal] = useState(false)
  const [showUnlockFeature, setShowUnlockFeature] = useState(false)
  const { getMeetingDetail } = useMeetingActions()
  const { meetingItem } = useMeeting()
  const { isAuthenticated, permissions} = useContext(JamyContext)

  
  useEffect(() => {
    setLoadingFolders(true)
    foldersService.getAllFolders().then((response) => {
      setFolders(response.data.results)
    }).finally(() => {
      setLoadingFolders(false)
    })
  }, [])
  
  const handleSubmit = async () => {
    const folderId = form.getFieldValue('folderId')
    setLoading(true)
    if (!meetingItem) return
    try {
      meetingItem.folder_id = folderId
      await meetingsService.updateMeeting({ folder_id: folderId }, meetingItem.id)
      await getMeetingDetail(meetingItem.id)
      toast.success('Folder added to meeting', { theme: 'colored', toastId: 'add-folder-success' })
      setShowModalAddToFolder(false)
      setLoading(false)
      form.resetFields()
    } catch (error) {
      toast.error('Error adding folder to meeting', { theme: 'colored', toastId: 'add-folder-error' })
      setLoading(false)
    }
  }
  
  const onRemoveFolder = async () => {
    if (!meetingItem) return
    setLoading(true)
    try {
      meetingItem.folder_id = null
      await meetingsService.updateMeeting({ folder_id: null }, meetingItem.id)
      await getMeetingDetail(meetingItem.id)
      toast.success('Folder removed', { theme: 'colored', toastId: 'remove-folder-success' })
      setShowModalAddToFolder(false)
      setRemoveFromFolderModal(false)
      setLoading(false)
    } catch (error) {
      toast.error('The folder has not been removed, try again', { theme: 'colored', toastId: 'remove-folder-error' })
      setLoading(false)
    }
  }
  
  // Open modal to remove a meeting from folder
  const onHandleRemoveFromFolder = async () => {
    setRemoveFromFolderModal(true)
  }

  return meetingItem?.folder === null ?
        <>
          <Popover
            content={
              <StyledFolderFormContainer
            style={{ width: '400px' }}
          >
            <Form
              form={form}
              layout='horizontal'
              style={{
                width: '100%',
              }}
              onFinish={handleSubmit}
            >
              <Form.Item
                name="folderId"
                rules={[{ required: true, message: 'Please select a folder' }]}
                style={{
                  padding: '10px 0',
                  margin: '0',
                }}
              >
                <Select
                  placeholder="Select a folder"
                  options={folders.map((folder) => ({
                    value: folder.id,
                    label: folder.name,
                  }))}
                  optionRender={(option) => (
                    <RenderItem label={option.label as string} color={folders.find((folder) => folder.id === option.value)?.color as string} />
                  )}
                  labelRender={(option) => (
                    <RenderItem label={option.label as string} color={folders.find((folder) => folder.id === option.value)?.color as string} />
                  )}
                  loading={loadingFolders}
                />
              </Form.Item>
              <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                <Button type="default" htmlType="button" onClick={() => { setShowModalAddToFolder(false) }} disabled={loading}>
                  CANCEL
                </Button>
                <Button type="primary" htmlType="submit" loading={loading} disabled={loading}>
                  ADD
                </Button>
              </div>
            </Form>
          </StyledFolderFormContainer>
          }
          trigger="click"
          placement="bottom"
          open={showModalAddToFolder}
          onOpenChange={() => {
            if (!isAuthenticated) return null
            if (permissions && !permissions.includes('folders')) return setShowUnlockFeature(!showUnlockFeature)
            setShowModalAddToFolder(!showModalAddToFolder)
            }}
          >
            <span
              style={{
                color: '#5333C1',
                fontWeight: 'bold',
                cursor: 'pointer',
                verticalAlign: 'middle',
                minWidth: '120px',
                opacity: !isAuthenticated ? 0.5 : 1,
              }}
            >
            {loading ?
              <Loading size='small' />
              :
              'ADD TO FOLDER'
            }
            </span>
        </Popover>
        <UnlockFeature
            namePlan="Premium"
            featureName="Add to folder"
            showModal={showUnlockFeature}
            handleShowModal={() => setShowUnlockFeature(!showUnlockFeature)}
            handleUpgrade={() => {}}
          />
        </>
        :
        <div
          style={{
            backgroundColor: meetingItem?.folder?.color,
            fontWeight: 'bold',
            verticalAlign: 'middle',
            padding: '8px 12px',
            borderRadius: '4px',
            color: 'white',
            minWidth: '120px',
          }}
        >
          {loading ? 
            <Loading 
              size='small'
            />
          :
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: isAuthenticated ? 'space-between' : 'center' }}>
              {meetingItem?.folder?.name}
              {
                isAuthenticated && (
                  <span onClick={onHandleRemoveFromFolder} style={{ marginLeft: '4px', cursor: 'pointer' }}>
                  ×
                  </span>
                )
              }
            </div>
          }
        <Modal
          open={removeFromFolderModal}
          onCancel={() => setRemoveFromFolderModal(false)}
          footer={[
            <Button
              key="back"
              onClick={() => setRemoveFromFolderModal(false)}
              disabled={loading}
            >
              Cancel
            </Button>,
            <Button
              key="remove"
              type="primary"
              onClick={onRemoveFolder}
              disabled={loading}
            >
              {loading ? 'Removing...' : 'Remove'}
            </Button>
          ]}
          title="Remove meeting from folder"
          width={600}
        >
          <p>Are you sure you want to remove this meeting from the folder?</p>
        </Modal>
        </div>
}

export default AddToFolder