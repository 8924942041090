export const validateWalks: () => boolean = () => {
  let isAllWalksCompleted = false

  const firstWalkCompleted = localStorage.getItem('first-walk-completed') || false
  const secondWalkCompleted = localStorage.getItem('second-walk-completed') || false
  const thirdWalkCompleted = localStorage.getItem('third-walk-completed') || false
  const fourthWalkCompleted = localStorage.getItem('fourth-walk-completed') || false
  const fifthWalkCompleted = localStorage.getItem('fifth-walk-completed') || false
  console.log(firstWalkCompleted)
  if (
    firstWalkCompleted && JSON.parse(firstWalkCompleted) === true &&
    secondWalkCompleted && JSON.parse(secondWalkCompleted) === true &&
    thirdWalkCompleted && JSON.parse(thirdWalkCompleted) === true &&
    fourthWalkCompleted && JSON.parse(fourthWalkCompleted) === true &&
    fifthWalkCompleted && JSON.parse(fifthWalkCompleted) === true
  ) {
    isAllWalksCompleted = true
  }

  return isAllWalksCompleted
}