import React from 'react'
import HelmetComponent from '../../../components/helmet'
import { LayoutMain } from '../../../layout'
import CreateTemplateContainer from '../../../containers/templatesContainer/createTemplateContainer'

export const CreateTemplatePage: React.FC = () => {
  return (
    <LayoutMain title={'CREATE TEMPLATE'}>
      <HelmetComponent title="Create Template"></HelmetComponent>
      <CreateTemplateContainer />
    </LayoutMain>
  )
}

export default CreateTemplatePage
