import React, { createContext, useState, useContext } from 'react'

interface MeetingContextType {
  // Estado
  meetingItem: Meetings.Results | null
  isLoadingMeeting: boolean
  isLoadingUpdateMeeting: boolean
  
  // Acciones
  setMeetingItem: (meeting: Meetings.Results) => void
  setIsLoadingMeeting: (value: boolean) => void
  setIsLoadingUpdateMeeting: (value: boolean) => void
}

const MeetingContext = createContext<MeetingContextType | undefined>(undefined)

export const MeetingProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [meetingItem, setMeetingItem] = useState<Meetings.Results | null>(null)
  const [isLoadingMeeting, setIsLoadingMeeting] = useState(true)
  const [isLoadingUpdateMeeting, setIsLoadingUpdateMeeting] = useState(false)

  const value = {
    // Estado
    meetingItem,
    isLoadingMeeting,
    isLoadingUpdateMeeting,
    
    // Acciones
    setMeetingItem,
    setIsLoadingMeeting,
    setIsLoadingUpdateMeeting
  }

  return (
    <MeetingContext.Provider value={value}>
      {children}
    </MeetingContext.Provider>
  )
}

export const useMeeting = () => {
  const context = useContext(MeetingContext)
  if (context === undefined) {
    throw new Error('useMeeting must be used within a MeetingProvider')
  }
  return context
} 