import { useNavigate, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import ComponentsDataService from '../../../services/components'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import TestsComponents from '../../../components/testsComponents'
import MeetingsDataService from '../../../services/meetings'
import moment from 'moment'
import { TableColumnsType } from 'antd'
import { Spin } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

const TestsComponentContainer: React.FC = () => {
  const { id } = useParams()
  const [testsComponent, setTestsComponent] = useState<any>()
  const navigate = useNavigate()
  const componentDataService = new ComponentsDataService()

  const [options, setOptions] = useState<any>([])
  const [searchText, setSearchText] = useState('')
  const meetingService = new MeetingsDataService()
  const [optionSelected, setOptionSelected] = useState<any>()
  const [loadingSearch, setLoadingSearch] = useState(false)
  const [notFoundContent, setNotFoundContent] = useState(false)

  const [componentName, setComponentName] = useState('')

  let dataTestsComponent: any[] = []

  const handleSearch = (value: string) => {
    setSearchText(value)
    if (value === '') {
      setOptions([])
      setOptionSelected(undefined)
      setNotFoundContent(false)
    }
  }

  const onSelect = (value: string, option: any) => {
    setOptionSelected(option.element)
    setSearchText(value)
    setOptions([])
  }

  const getTestsComponentsByIdData = (id: string) => {
    componentDataService
      .getTestsComponentById(id)
      .then((response: any) => {
        console.log(response)
        if (response.data && response.data.results) {
          setComponentName(response.data.name)
          setTestsComponent(response.data.results)
        } else {
          setTestsComponent(response.data)
          setComponentName(response.data[0].meeting_component.component.name)
        }
      })
      .catch((error: AxiosError) => {
        toast.error(error.message, { theme: 'colored' })
        console.error(error.message)
      })
  }

  const handleClickCreateTestButton = () => {
    setLoadingSearch(true)
    componentDataService
      .createMeetingComponentTest({
        component_id: id || '',
        meeting_id: optionSelected.id
      })
      .then((response) => {
        toast.success('Test added to the queue. It will be done in <1 min.', { theme: 'colored' })
        setOptionSelected(undefined)
        setSearchText('')
        console.info(response)
        getTestsComponentsByIdData(id || '')
        setLoadingSearch(false)
      })
      .catch((error: AxiosError) => {
        toast.error(error.message, { theme: 'colored' })
        console.error(error.message)
        setLoadingSearch(false)
      })
  }

  const columns: TableColumnsType<any> = [
    { title: <strong>Meeting</strong>,
      dataIndex: 'meeting',
      key: 'meeting' 
    },
    {
      title: <strong>Test Creation Date</strong>,
      dataIndex: 'creation_date',
      key: 'creation_date'
    },
    {
      title: <strong>Status</strong>,
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => {
        if (status === 'processing') {
          return <Spin size="small" />;
        }
        if (status === 'done') {
          return (
            <CheckCircleOutlined
              style={{ color: 'green', fontSize: '16px' }}
            />
          );
        }
        return status;
      },
    },
  ]

  const handleGoBackButton = () => {
    navigate(`/components/create/${id}`)
  }

  useEffect(() => {
    if (id) {
      getTestsComponentsByIdData(id)
    }
  }, [id])

  useEffect(() => {
    const timeoutFn = setTimeout(() => {
      if (searchText !== '') {
        setLoadingSearch(true)
        setNotFoundContent(false)
        meetingService
          .getMeetingsSearch(searchText)
          .then((response) => {
            const op: any[] = []
            response.data.results.forEach((element, index) => {
              op.push({
                label: (
                  <div key={element.id + index}>
                    <div>
                      <strong>{element.name}</strong>
                      {element.start_time && (
                        <p
                          style={{
                            fontSize: '10px',
                            color: 'gray',
                            margin: '0'
                          }}
                        >
                          {moment(element.start_time).format(
                            'DD/MM/YYYY HH:mm A'
                          )}
                        </p>
                      )}
                    </div>
                    <div>{element.summary?.substring(0, 100)}</div>
                  </div>
                ),
                value: element.name,
                element
              })
            })
            setOptions(op)
            setLoadingSearch(false)
            !op.length && setNotFoundContent(true)
          })
          .catch((e) => {
            console.log(e)
            setLoadingSearch(false)
          })
      }
    }, 2000)

    return () => {
      clearTimeout(timeoutFn)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText])

  if (testsComponent) {
    dataTestsComponent = testsComponent.map((element: any, index: number) => {
      return {
        key: index,
        meeting: element.meeting_component.name.split("__")[1],
        creation_date: moment(element.created_date).format('MMM DD YYYY HH:mm A'),
        status: element.meeting_component.status,
        record: element
      }
    })
  }

  return (
    <TestsComponents
      options={options}
      handleSearch={handleSearch}
      onSelect={onSelect}
      optionSelected={optionSelected}
      loadingSearch={loadingSearch}
      handleClickCreateTestButton={handleClickCreateTestButton}
      searchText={searchText}
      notFoundContent={notFoundContent}
      data={dataTestsComponent}
      columns={columns}
      goBackButton={handleGoBackButton}
      componentName={componentName}
    />
  )
}

export default TestsComponentContainer
