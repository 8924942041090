import React, { useContext, useEffect, useState } from 'react'
import template from '../../assets/icons/template.png'
import component from '../../assets/icons/component.png'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import TemplatesDataService from '../../services/templates'
import { steps } from '../../components/walkthroughs/reportCreator'
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS } from 'react-joyride'
import JamyContext from '../../context/jamyContext'
import UserDataService from '../../services/users'
import { validateWalks } from '../../utils/validateWalks'

const ReportCreatorContainer: React.FC = () => {
  const navigate = useNavigate()
  const templatesDataService = new TemplatesDataService()
  const [templates, setTemplates] = useState<Templates.ResponseTemplates>()
  const [runWalk, setRunWalk] = useState(false)
  const { user, getUser } = useContext(JamyContext)
  const userDataService = new UserDataService()
  const [stepIndex, setStepIndex] = useState(0)

  useEffect(() => {
    templatesDataService
      .getAllTemplates()
      .then((response) => {
        setTemplates(response.data)
      })
      .catch((error) => {
        setRunWalk(true)
        console.error(error)
      })
  }, [])

  useEffect(() => {
    if (user) {
      !user.walkthroughs.templates_walkthrough && setRunWalk(true)
    }
  }, [user])

  const updateUserWalkValue = () => {
    userDataService
      .updateUserWalkthroughs(
        {
          templates_walkthrough: true
        },
        user ? user.id : ''
      )
      .then((data) => {
        console.log('User updated', data)
        getUser()
      })
      .catch((e) => {
        console.error('Error updating user templates_walkthrough value', e)
      })
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action, index, status, type } = data

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1))
    } else if ([STATUS.FINISHED].includes(status as any)) {
      localStorage.setItem('first-walk-completed', 'true')
      const isAllWalksCompleted = validateWalks()
      if (isAllWalksCompleted) {
        updateUserWalkValue()
      }
      setRunWalk(false)
    } else if ([STATUS.SKIPPED].includes(status as any)) {
      updateUserWalkValue()
      setRunWalk(false)
    }
  }

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Joyride
        run={runWalk}
        steps={steps}
        showSkipButton={true}
        hideBackButton={false}
        continuous={true}
        hideCloseButton
        styles={{ options: { primaryColor: '#E0AA25' } }}
        disableCloseOnEsc
        disableOverlayClose
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          backgroundColor: 'white',
          borderRadius: '20px',
          width: '50%',
          marginTop: '15%'
        }}
      >
        <div
          style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}
          className="templates-creator"
        >
          <img
            src={template}
            alt="Template"
            style={{ width: '100px', height: '100px' }}
          />
          <Button
            type="dashed"
            style={{ marginTop: '10px' }}
            onClick={() => navigate('/templates')}
          >
            Templates
          </Button>
        </div>
        <div
          style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}
          className="components-creator"
        >
          <img
            src={component}
            alt="Component"
            style={{ width: '100px', height: '100px' }}
          />
          <Button
            type="dashed"
            style={{ marginTop: '10px' }}
            onClick={() => navigate('/components')}
          >
            Components
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ReportCreatorContainer
