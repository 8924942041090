import styled from 'styled-components'

export const StyledFoldersGrid = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
`

export const StyledFolder = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
`

export const StyledFolderLabels = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const StyledFolderItems = styled.span`
  font-size: 10px;
  color: gray;
  margin-top: 5px;
`

export const StyledFolderName = styled.span`
  font-size: 14px;
  font-weight: 500;
`