import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from 'react'
import { StyledH2 } from '../../styles/styledComponents'
import {
  StyledComponentContent,
  StyledComponentPromptItem,
  StyledComponentPromptItemContent,
  StyledComponentPromptItemDeleteImg,
  StyledComponentPromptItemNumber,
  StyledComponentPromptItemText,
  StyledTestComponentButtonContent
} from './styledComponents'
import {
  Button,
  Form,
  FormInstance,
  Input,
  Select,
  Switch,
  Table,
  TableProps,
  Tooltip
} from 'antd'
import deleteImg from '../../assets/icons/delete.svg'
import { FormDataF } from '../../containers/componentsContainer/createComponentContainer'
import { useNavigate, useParams } from 'react-router-dom'
import ComponentsDataService from '../../services/components'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { InfoCircleOutlined } from '@ant-design/icons'
import JamyContext from '../../context/jamyContext'

const { Option } = Select

type PropsComponent = {
  form: FormInstance
  formValues: FormDataF
  instruction: string
  dataArray: FormDataF[]
  isFormValuesValid: boolean
  options: { name: string; value: string }[]
  setDataArray: Dispatch<SetStateAction<FormDataF[]>>
  setInstruction: Dispatch<SetStateAction<string>>
  onFinish: (data: any) => void
  handleChangeForm: (value: any, input: string) => void
  handleChangeFormSubmit: () => void
  handleOptionChange: (value: string, name: string) => void
  addOptionToArray: () => void
  editors: any[]
}

const Component: React.FC<PropsComponent> = ({
  form,
  instruction,
  formValues,
  options,
  setInstruction,
  handleChangeForm,
  onFinish,
  handleChangeFormSubmit,
  handleOptionChange,
  addOptionToArray,
  setDataArray,
  dataArray,
  isFormValuesValid,
  editors
}) => {
  const navigate = useNavigate()
  const { id } = useParams()
  const [componentEdit, setComponentEdit] =
    useState<Components.ResultComponent>()
  const [componentType, setComponentType] = useState('')
  const componentDataService = new ComponentsDataService()
  const { user } = useContext(JamyContext)
  const filteredEditors = editors.length
    ? editors.filter((val) => val.id !== user?.id)
    : []
  let isTheUserAnEditor: undefined | boolean

  useEffect(() => {
    if (id) {
      componentDataService
        .getComponentById(id)
        .then((response) => {
          setComponentEdit(response.data)
        })
        .catch((error: AxiosError) => {
          toast.error(error.message, { theme: 'colored' })
          console.error(error.message)
        })
    }
  }, [id])

  useEffect(() => {
    if (componentEdit) {
      if (componentEdit.component_type === 'GENERATION') {
        const result = componentEdit.prompt.prompt[0].name
          .split('\n')
          .map((line: string) => line.substring(3, 1000))

        form.setFields(
          result.map((val: string, index: number) => ({
            name: ['instructions', index],
            value: val.trimStart()
          }))
        )
      }

      if (componentEdit.component_type === 'FORM') {
        setDataArray(componentEdit.prompt.prompt)
      }

      setComponentType(componentEdit.component_type)
      form.setFieldValue('name', componentEdit.name)
      form.setFieldValue('edit', true)
      form.setFieldValue('id', componentEdit.id)
      form.setFieldValue('component_type', componentEdit.component_type)
      form.setFieldValue('description', componentEdit.description)
      form.setFieldValue('public', componentEdit.public)
      form.setFieldValue(
        'editors',
        componentEdit.editors
          .map((val) => val.id)
          .filter((val) => val !== user?.id)
      )
    }
  }, [componentEdit])

  const renderGeneration = () => {
    return (
      <div>
        <Input
          name="instruction"
          value={instruction}
          placeholder="Write Instructions"
          onChange={(e) => setInstruction(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              e.preventDefault()
              const currentInstructions: string[] =
                form.getFieldValue('instructions') || []
              currentInstructions.push(instruction)
              form.setFields(
                currentInstructions.map((val: string, index: number) => ({
                  name: ['instructions', index],
                  value: val
                }))
              )
              setInstruction('')
            }
          }}
        ></Input>
        <Button
          type="primary"
          disabled={instruction.length === 0}
          htmlType="button"
          style={{ width: '100%', marginTop: '10px' }}
          onClick={() => {
            const currentInstructions: string[] =
              form.getFieldValue('instructions') || []
            currentInstructions.push(instruction)
            form.setFields(
              currentInstructions.map((val: string, index: number) => ({
                name: ['instructions', index],
                value: val
              }))
            )

            setInstruction('')
          }}
        >
          Add Instruction
        </Button>
      </div>
    )
  }

  const renderForm = () => {
    return (
      <div>
        <Input
          style={{ marginBottom: '10px' }}
          name="name"
          value={formValues.name}
          placeholder="Field, Question, Variable, etc..."
          onChange={(e) => handleChangeForm(e.target.value, 'name')}
        ></Input>

        <Select
          placeholder="Type"
          onChange={(e) => handleChangeForm(e, 'type')}
          style={{ marginBottom: '10px', width: '100%' }}
          value={formValues.type !== '' ? formValues.type : undefined}
        >
          <Option value="short_text">Short Text (3 words)</Option>
          <Option value="text">Text (30 words)</Option>
          <Option value="long_text">Long Text (100 words)</Option>
          <Option value="bool">Boolean (True or False)</Option>
          <Option value="numeric">Numeric</Option>
          <Option value="date">Date (YYYY-MM-DD)</Option>
          <Option value="select">Select</Option>
          <Option value="multi_select">Multi Select</Option>
        </Select>

        {formValues.type !== 'select' && formValues.type !== 'multi_select' ? (
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              marginBottom: '10px'
            }}
          >
            <Input.TextArea
              style={{ flexGrow: 1 }}
              name="description"
              value={formValues.description}
              placeholder={`Add a description to help Jamy know how to answer this specific item.

For example, if the item is a question like 'How many years of experience does the candidate have?' you could add this as a Description:

'Answer with the specific number of years of experience the candidate has, as well as context on his most recent job experience.

This item could be of type "text" or "long_text, for example."'`}
              autoSize={{ minRows: 11, maxRows: 13 }}
              onChange={(e) => handleChangeForm(e.target.value, 'description')}
            />
            <Tooltip
              placement="top"
              title={
                <div>
                  <p>
                    Add a description to help Jamy know how to answer this
                    specific item.
                  </p>
                  <p>
                    For example, if the item is a question like "How many years
                    of experience does the candidate have?" you could add this
                    for the Description:
                  </p>
                  <p></p>
                  <p>
                    "Answer with the specific number of years as well as a bit
                    of context on his most previous job experience." Or, for
                    example, if the item is of type Date, and the item Name is
                    "launch_date," you could add this for the Description:
                  </p>
                  <p>
                    <strong>
                      "The launch date is the expected date of when the customer
                      wants to kick off the project."
                    </strong>
                  </p>
                </div>
              }
            >
              <InfoCircleOutlined
                style={{
                  color: 'black',
                  marginLeft: '10px',
                  cursor: 'pointer'
                }}
              />
            </Tooltip>
          </div>
        ) : (
          <div>
            {renderOptions()}
            <p
              style={{
                fontWeight: 'bold',
                fontSize: '12px',
                color: '#5333C1',
                textAlign: 'end',
                cursor: 'pointer'
              }}
              onClick={addOptionToArray}
            >
              ADD ANOTHER OPTION
            </p>
          </div>
        )}

        <Button
          type="primary"
          disabled={!isFormValuesValid}
          htmlType="button"
          style={{ width: '100%', marginTop: '10px' }}
          onClick={handleChangeFormSubmit}
        >
          Add item
        </Button>
      </div>
    )
  }

  const renderOptions = () => {
    return options.map((option, index) => (
      <Input
        key={index}
        style={{ marginBottom: '10px' }}
        placeholder={`Option ${index + 1}`}
        name={option.name}
        value={option.value}
        onChange={(e) => handleOptionChange(e.target.value, option.name)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleChangeFormSubmit()
          }
        }}
      />
    ))
  }

  const columns: TableProps<FormDataF>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description'
    },
    {
      title: 'Options',
      dataIndex: 'options',
      key: 'description',
      render: (_, record: any) => (
        <StyledComponentPromptItemDeleteImg
          src={deleteImg}
          onClick={() => {
            setDataArray(
              dataArray.filter((item) => item['name'] !== record.name)
            )
          }}
        />
      )
    }
  ]

  if (componentEdit) {
    isTheUserAnEditor = componentEdit.editors.some(
      (el: any) => el.id === user?.id
    )
  }

  const handleOnFinish: (values: any) => void = (values) => {
    if (componentEdit === undefined && isTheUserAnEditor === undefined) {
      onFinish(values)
    }

    if (componentEdit && isTheUserAnEditor) {
      onFinish(values)
    }

    return undefined
  }

  return (
    <Form form={form} layout="vertical" onFinish={handleOnFinish}>
      <div style={{ display: 'flex', width: '100%' }}>
        <div style={{ width: '30%' }}>
          <StyledComponentContent>
            <StyledH2>GENERAL INFORMATION</StyledH2>
            <div>
              <Form.Item name={'id'} hidden>
                <Input></Input>
              </Form.Item>
              <Form.Item name={'name'}>
                <Input placeholder="Name"></Input>
              </Form.Item>
              <div>
                <div
                  style={{
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginBottom: '10px'
                  }}
                >
                  <Form.Item
                    name={'component_type'}
                    style={{ flexGrow: 1, marginBottom: 0 }}
                    className="walk-target"
                  >
                    <Select
                      value={componentType}
                      placeholder="Select component type"
                      onChange={(e: string) => setComponentType(e)}
                    >
                      <Option value="FORM">FORM</Option>
                      <Option value="GENERATION">GENERATION</Option>
                    </Select>
                  </Form.Item>
                  <Tooltip
                    placement="right"
                    title={
                      <p>
                        <strong>Form Component</strong> <br /> <br />
                        A Form Component consists of items that can be variables
                        or questions that Jamy will fill out based on the
                        Meeting content. Each item has a name, type and
                        description. This type of Component is specially useful
                        if you want to, for example, gather customer attributes,
                        answer specific questions, fill out dates, choose from
                        specific multiple options, etc.
                        <br /> <br />
                        <strong>Generation Component</strong> <br /> <br /> A
                        Generation Component consists of a set of instructions
                        that Jamy will follow to generate a specific text. You
                        can add as much instructions as you want. For example,
                        the “Summary” Component is a Generation Component.
                      </p>
                    }
                  >
                    <InfoCircleOutlined
                      style={{
                        color: 'black',
                        marginLeft: '10px',
                        cursor: 'pointer'
                      }}
                    />
                  </Tooltip>
                </div>
              </div>
              <Form.Item name={'description'}>
                <Input placeholder="Description"></Input>
              </Form.Item>
              <Form.Item name={'editors'}>
                <Select
                  showSearch
                  mode="multiple"
                  placeholder="Select an editor"
                  optionFilterProp="label"
                  filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '')
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? '').toLowerCase())
                  }
                  options={
                    filteredEditors.length
                      ? filteredEditors.map((val) => {
                          return {
                            value: val.id,
                            label: val.first_name + ' ' + val.last_name
                          }
                        })
                      : []
                  }
                />
              </Form.Item>
              <Form.Item name={'edit'} hidden={true}>
                <Switch />
              </Form.Item>
              <Form.Item
                name={'public'}
                label="Make public"
                layout="horizontal"
              >
                <Switch />
              </Form.Item>
            </div>
          </StyledComponentContent>
          <StyledComponentContent>
            <div hidden={componentType !== '' ? false : true}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'start'
                }}
              >
                <StyledH2>
                  {componentType === 'FORM' ? 'ADD ITEMS' : 'INSTRUCTIONS'}
                </StyledH2>
                <Tooltip
                  title={
                    <div>
                      <p>
                        The Item name can be a Question you want Jamy to answer
                        or a Field or Variable that you want Jamy to fill the
                        value of.
                      </p>
                      <p>
                        For example, you could add "launch_date" as the item
                        Name, choose Date type and then explain what that
                        variable is in the Description.
                      </p>
                    </div>
                  }
                >
                  <InfoCircleOutlined style={{ color: 'black' }} />
                </Tooltip>
              </div>

              {componentType === 'GENERATION'
                ? renderGeneration()
                : renderForm()}
            </div>
          </StyledComponentContent>
        </div>
        <div style={{ width: '70%' }}>
          {componentEdit && (
            <StyledTestComponentButtonContent>
              <Button
                type="primary"
                onClick={() =>
                  navigate(`/components/tests/${componentEdit.id}`)
                }
              >
                TEST COMPONENT
              </Button>
            </StyledTestComponentButtonContent>
          )}
          <StyledComponentContent>
            <StyledH2>PROMPT</StyledH2>

            <Form.List name="instructions">
              {(fields, { remove }) =>
                fields.map(({ key, name, ...restField }, index) => {
                  return (
                    <StyledComponentPromptItem key={key}>
                      <StyledComponentPromptItemContent>
                        <StyledComponentPromptItemNumber>
                          {index + 1}
                        </StyledComponentPromptItemNumber>
                        <StyledComponentPromptItemText>
                          <Form.Item
                            noStyle
                            {...restField}
                            name={[name]}
                            shouldUpdate
                          >
                            <Input style={{ width: '100%' }} />
                          </Form.Item>
                        </StyledComponentPromptItemText>
                      </StyledComponentPromptItemContent>
                      <StyledComponentPromptItemDeleteImg
                        src={deleteImg}
                        onClick={() => remove(name)}
                      />
                    </StyledComponentPromptItem>
                  )
                })
              }
            </Form.List>

            <div hidden={componentType !== 'FORM'}>
              <Table<FormDataF>
                columns={columns}
                dataSource={dataArray}
                pagination={false}
              />
            </div>
            <div
              style={{
                width: '100%',
                justifyContent: 'end',
                display: 'flex',
                marginTop: '20px'
              }}
              hidden={
                form.getFieldValue('instructions') &&
                form.getFieldValue('instructions').length === 0 &&
                dataArray.length === 0
              }
            >
              <Button
                type="default"
                htmlType="button"
                style={{ marginRight: '10px' }}
                onClick={() => navigate('/components')}
              >
                CANCEL
              </Button>
              <Tooltip
                title={
                  componentEdit &&
                  !isTheUserAnEditor &&
                  "You don't have permissions to edit this Component"
                }
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  hidden={componentEdit && !isTheUserAnEditor}
                >
                  {componentEdit ? 'UPDATE COMPONENT' : 'CREATE COMPONENT'}
                </Button>
              </Tooltip>
            </div>
          </StyledComponentContent>
        </div>
      </div>
    </Form>
  )
}

export default Component
