import React from 'react'
import { AutoComplete, Input } from 'antd'
import CalendarConfiguration from '../../containers/CalendarConfiguguration'

type PropsSearchBarAutocomplete = {
  placeholder?: string
  onSelect: (value: string, option?: any) => void
  handleSearch: (value: string) => void
  options: Meetings.Results[] | []
  value?: string | null
  loading?: boolean
  onCheckPermission?: () => void // This is used to check if the user has the permission to add a meeting to a folder
}

const SearchBarAutoComplete: React.FC<PropsSearchBarAutocomplete> = ({
  onSelect,
  placeholder,
  handleSearch,
  options,
  value,
  loading,
  onCheckPermission,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%'
      }}
    >
      <AutoComplete
        style={{ width: !placeholder ? '50%' : '100%' }}
        options={options}
        onSelect={onSelect}
        onSearch={handleSearch}
        value={value}
      >
        <Input.Search
          size="large"
          placeholder={
            placeholder
              ? placeholder
              : "Search by meeting name or participants' email"
          }
          enterButton
          loading={loading}
          onClick={onCheckPermission}
        />
      </AutoComplete>
      <CalendarConfiguration show={placeholder ? false : true} />
    </div>
  )
}

export default SearchBarAutoComplete
