import styled from 'styled-components'

export const StyledContent = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 25px;
  margin: 10px;
`

export const StyledAutocompleteWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr min-content;
  column-gap: 1.5rem;
`

export const StyledTableWrapper = styled.div`
  margin-top: 2rem;

  table {
    border-collapse: separate;
    border-spacing: 0 1em;

    .ant-table-thead {
      .ant-table-cell {
        background-color: transparent;
        border: none;
        ::before {
          background-color: transparent !important;
        }
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        cursor: pointer;
        background-color: #f5f5f5;
        margin-bottom: 1rem;
        td {
          /* border: 1px solid #e4e4e4; */
        }
      }

      .ant-table-expanded-row {
        .ant-table-cell {
          background-color: #fff;
          border: none;
        }
      }
    }
  }
`
