import styled from 'styled-components'

export const StyledFoldersGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px 0;
  margin-top: 20px;
  background-color: white;
  border-radius: 12px;
  min-height: 100vh;
`

export const StyledFolderIcon = styled.img`
  width: 52px;
  height: 52px;
`

export const StyledFolderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  height: 150px;
`

export const StyledFolderItems = styled.span`
  font-size: 10px;
  color: gray;
  margin-top: 5px;
`