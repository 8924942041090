import { createContext } from 'react'
import ILoginUserData from '../types/auth/login'
import ILogoutUserData from '../types/auth/logout'

export interface IJamyContext {
  isAuthenticated: boolean
  loading: boolean
  user: User.IUserDataResponse | null
  org: Orgs.IOrgsResults | null
  google_connection: boolean
  slack_connection: boolean
  permissions: string[],
  recall_calendar_auth_token: string
  google_calendar_connection: boolean
  outlook_calendar_connection: boolean
  login: (data: ILoginUserData) => void
  logout: (data: ILogoutUserData) => void
  getAllSlackUsers: () => void
  getOrgInformation: () => void
  getUser: () => void
  isAnonymous: boolean
  getAnonymous: () => void
}

export const initialState: IJamyContext = {
  isAuthenticated: false,
  loading: false,
  user: null,
  org: null,
  permissions: [],
  recall_calendar_auth_token: '',
  google_connection: false,
  slack_connection: false,
  google_calendar_connection: false,
  outlook_calendar_connection: false,
  login: (data: ILoginUserData) => {},
  logout: (data: ILogoutUserData) => {},
  getAllSlackUsers: () => {},
  getOrgInformation: () => {},
  getUser: () => {},
  isAnonymous: false,
  getAnonymous: () => {}
}

const JamyContext = createContext<IJamyContext>(initialState)

export default JamyContext
