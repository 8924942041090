import React from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import ComponentsContainer from '../../containers/componentsContainer'

export const ComponentsPage: React.FC = () => {
  return (
    <LayoutMain title={'COMPONENTS'}>
      <HelmetComponent title="Components"></HelmetComponent>
      <ComponentsContainer />
    </LayoutMain>
  )
}

export default ComponentsPage
