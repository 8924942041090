import React, { useContext, useEffect, useState } from 'react'
import Component from '../../../components/component'
import { useForm } from 'antd/es/form/Form'
import JamyContext from '../../../context/jamyContext'
import ComponentsDataService from '../../../services/components'
import { toast } from 'react-toastify'
import { AxiosError } from 'axios'
import Loading from '../../../components/loading'
import Joyride, { ACTIONS, CallBackProps } from 'react-joyride'
import { steps } from '../../../components/walkthroughs/components/create'
import { useNavigate } from 'react-router-dom'
import { validateWalks } from '../../../utils/validateWalks'
import UserDataService from '../../../services/users'

export interface FormDataF {
  key: string
  name: string
  description: string
  type: string
}

const CreateComponentContainer: React.FC = () => {
  const { org, user, getUser } = useContext(JamyContext)
  const [formValues, setFormValues] = useState<FormDataF>({
    key: '',
    name: '',
    description: '',
    type: ''
  })
  const [options, setOptions] = useState<{ name: string; value: string }[]>([
    { name: 'option_1', value: '' }
  ])
  const [loading, setLoading] = useState(false)
  const [isFormValuesValid, setIsFormValuesValid] = useState(false)
  const [dataArray, setDataArray] = useState<FormDataF[]>([])
  const [form] = useForm()
  const [instruction, setInstruction] = useState<string>('')
  const componentsDataService = new ComponentsDataService()
  const [runWalk, setRunWalk] = useState(false)
  const [stepIndex] = useState(1)
  const navigate = useNavigate()
  const userDataService = new UserDataService()

  useEffect(() => {
    if (user) {
      !user.walkthroughs.templates_walkthrough && setRunWalk(true)
    }
  }, [user])
  const usersService = new UserDataService()
  const [editors, setEditors] = useState<any[]>([])

  const onFinish = (data: any) => {
    const { instructions: _, ...restOfData } = data

    setLoading(true)
    const dataRequest = {
      ...restOfData,
      organization: org?.id,
      owner_id: user?.id,
      editors_id: [user ? user.id : '', ...(data.editors || [])],
      public: data.public ? data.public : false,
      prompt:
        data.component_type === 'GENERATION'
          ? handlePromptGeneration()
          : handlePromptForm()
    }

    if (data.edit === false || data.edit === undefined) {
      componentsDataService
        .createComponent(dataRequest)
        .then((response: any) => {
          toast.success('Component created succesfully', { theme: 'colored' })
          setLoading(false)
          navigate(`/components/create/${response.data.id}/`)
        })
        .catch((error: AxiosError) => {
          console.error(error)
          setLoading(false)
          toast.error(`${error.message}`, { theme: 'colored' })
        })
    } else {
      componentsDataService
        .updateComponentById(data.id, dataRequest)
        .then((response) => {
          toast.success('Component updated succesfully', { theme: 'colored' })
          setLoading(false)
          navigate('/components')
        })
        .catch((error: AxiosError) => {
          console.error(error)
          setLoading(false)
          toast.error(`${error.message}`, { theme: 'colored' })
        })
    }
  }

  const handlePromptForm = () => {
    let dataForm = dataArray

    dataForm.forEach((element) => {
      if (element.type === 'select' || element.type === 'multi_select') {
        element.description = concatenateValues()
      }
    })

    return { prompt: dataForm }
  }

  const concatenateValues = () => {
    return options
      .map((item) => item.value.trim()) // Elimina espacios en blanco en los valores
      .filter((value) => value !== '') // Filtra valores vacíos
      .join(', ') // Une los valores con comas
  }

  const handlePromptGeneration = () => {
    return {
      prompt: [
        {
          name: form
            .getFieldValue('instructions')
            .map(
              (elemento: string, index: number) => `${index + 1}. ${elemento}`
            )
            .join('\n '),
          description: '',
          type: ''
        }
      ]
    }
  }

  const handleChangeForm = (value: string, input: string) => {
    setFormValues({
      ...formValues,
      [input]: value
    })
  }

  useEffect(() => {
    if (formValues.name.trim() !== '' && formValues.type.trim() !== '') {
      if (formValues.type !== 'select' && formValues.type !== 'multi_select') {
        if (formValues.description.trim() !== '') {
          setIsFormValuesValid(true)
        }
      } else {
        setIsFormValuesValid(options[0].value !== '' ? true : false)
      }
    }
  }, [formValues, options])

  const handleChangeFormSubmit = () => {
    if (isFormValuesValid) {
      setDataArray([
        ...dataArray,
        formValues.type === 'select' || formValues.type === 'multi_select'
          ? {
              ...formValues,
              description: concatenateValues()
            }
          : formValues
      ])
      setFormValues({
        key: '',
        name: '',
        description: '',
        type: ''
      })
      setOptions([{ name: 'option_1', value: '' }])
    }
  }

  const handleOptionChange = (value: string, name: string) => {
    setOptions((prev) =>
      prev.map((option) =>
        option.name === name ? { ...option, value } : option
      )
    )
  }

  const addOptionToArray = () => {
    const newOptionKey = `option_${options.length + 1}`
    setOptions((prev) => [
      ...prev,
      { name: newOptionKey, value: '' } // Agrega un nuevo objeto con clave dinámica
    ])
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { action } = data

    if (action === ACTIONS.CLOSE) {
      localStorage.setItem('third-walk-completed', 'true')
      const isAllWalksCompleted = validateWalks()
      if (isAllWalksCompleted) {
        userDataService
          .updateUserWalkthroughs(
            {
              templates_walkthrough: true
            },
            user ? user.id : ''
          )
          .then((data) => {
            console.log('User updated', data)
            getUser()
          })
          .catch((e) => {
            console.error('Error updating user templates_walkthrough value', e)
          })
      }
      setRunWalk(false)
    }
  }

  useEffect(() => {
    usersService
      .getAllUsers()
      .then((response) => {
        setEditors(response.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <div>
      {loading ? (
        <Loading />
      ) : (
        <div>
          <Joyride
            run={runWalk}
            steps={steps}
            hideCloseButton
            styles={{ options: { primaryColor: '#E0AA25' } }}
            disableCloseOnEsc
            disableOverlayClose
            stepIndex={stepIndex}
            callback={handleJoyrideCallback}
            hideBackButton
          />
          <Component
            form={form}
            instruction={instruction}
            setInstruction={setInstruction}
            onFinish={onFinish}
            dataArray={dataArray}
            setDataArray={setDataArray}
            handleChangeForm={handleChangeForm}
            formValues={formValues}
            isFormValuesValid={isFormValuesValid}
            handleChangeFormSubmit={handleChangeFormSubmit}
            handleOptionChange={handleOptionChange}
            addOptionToArray={addOptionToArray}
            options={options}
            editors={editors}
          />
        </div>
      )}
    </div>
  )
}

export default CreateComponentContainer
