import { toast } from 'react-toastify'
import { useMeeting } from '../../context/meetingContext'
import MeetingsDataService from '../../services/meetings'

export const useMeetingActions = () => {
  const { 
    meetingItem,
    setMeetingItem,
    setIsLoadingMeeting,
    setIsLoadingUpdateMeeting 
  } = useMeeting()
  
  const meetingService = new MeetingsDataService()

  const getMeetingDetail = async (id: string) => {
    try {
      const response = await meetingService.getMeetingById(id)
      setMeetingItem(response.data)
    } catch (error: any) {
      if (error.status === 403) {
        toast.error('You do not have permission to access this meeting', { theme: 'colored', toastId: 'error-fetching-meeting' })
      } else {
        toast.error('Error fetching meeting', { theme: 'colored', toastId: 'error-fetching-meeting' })
      }
    } finally {
      setIsLoadingMeeting(false)
    }
  }

  const updateMeeting = async (data: Meetings.Results, arg: string) => {
    if (!meetingItem) {
      console.error('No meeting item found')
      return
    }

    setIsLoadingUpdateMeeting(true)
    
    try {
      await meetingService.updateMeeting(data, meetingItem.id)
      await getMeetingDetail(meetingItem.id)
      setIsLoadingUpdateMeeting(false)
      toast.success(`${arg} have been updated`, {
        theme: 'colored',
        toastId: 'update-success'
      })
    } catch (error) {
      toast.error(`${arg} haven't been updated correctly`, {
        theme: 'colored',
        toastId: 'update-error'
      })
    }
  }

  return {
    getMeetingDetail,
    updateMeeting
  }
} 