import { ReactNode } from 'react';
import { Step } from 'react-joyride';

const StepDescriptionContent: ReactNode = (
    <div style={{ fontWeight: "400", fontSize: "15px", textAlign: "left" }}>
        <p>
            It's <strong>important</strong> to add a good and representative description
            of your Template because Jamy will use that description
            to <strong>automatically determine</strong> when this Template should be applied 
            to generate a Meeting Report.
        </p>
    </div>
)

const StepComponentsListContent: ReactNode = (
    <div style={{ fontWeight: "400", fontSize: "15px", textAlign: "left" }}>
        <p>
            Add one or more Components to your Template. 
            Choose from pre-built Public Components or from the Components you've created.
        </p>
    </div>
)

export const steps: Step[] = [
    {
        content: StepDescriptionContent,
        locale: {
            skip: (
              <strong aria-label="skip" style={{ color: '#E0AA25' }}>
                Skip
              </strong>
            ),
            next: 'Next',
            back: 'Back',
            last: 'Last'
          },
        placement: 'right',
        target: '.template-description',
        styles: { options: {  width: 610 } },
        disableBeacon: true,
    },
    {
        content: StepComponentsListContent,
        locale: {
            skip: (
              <strong aria-label="skip" style={{ color: '#E0AA25' }}>
                Skip
              </strong>
            ),
            next: 'Next',
            back: 'Back',
            last: 'Close'
          },
        placement: 'right',
        target: '.components-list',
        styles: { options: {  width: 610 } },
        disableBeacon: true,
    }    
];
