import { Button, DatePicker, Form, GetProps, Input, Modal, Tooltip } from 'antd'
import { StyledH2V2 } from '../../styles/styledComponents'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { FormInstance } from 'antd/es/form/Form'
import { useEffect } from 'react'

dayjs.extend(customParseFormat)

type RangePickerProps = GetProps<typeof DatePicker.RangePicker>

type Props = {
  isOpen: boolean
  handleCancel: () => void
  formData: FormInstance
  onFinish: (data: any) => void
  dateText?: string
}

const CreateManualMeetingForm: React.FC<Props> = ({
  isOpen,
  handleCancel,
  formData,
  onFinish,
  dateText
}) => {
  const range = (start: number, end: number) => {
    const result = []
    for (let i = start; i < end; i++) {
      result.push(i)
    }
    return result
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today
    return current && current < dayjs().startOf('day')
  }

  // const disabledDateTime = (data: any) => {
  //   if (
  //     dayjs(dayjs(data).format('YYYY-MM-DD')).startOf('day') ===
  //     dayjs().startOf('day')
  //   ) {
  //     console.log('son iguales!')
  //   }
  //   return {
  //     disabledHours: () => range(0, dayjs().hour()),
  //     disabledMinutes: () => range(0, dayjs().minute())
  //   }
  // }

  useEffect(() => {
    if (dateText) {
      formData.setFieldValue('meeting-date', '')
      formData.setFieldValue('url-meeting', '')
      formData.setFieldValue('meeting-name', '')
      formData.setFieldValue('participants', undefined)
    }
  }, [])

  return (
    <Modal
      open={isOpen}
      footer={null}
      width={'60%'}
      onCancel={handleCancel}
      forceRender
    >
      <StyledH2V2>Create Meeting</StyledH2V2>
      <div
        style={{
          padding: '20px',
          width: '100%'
        }}
      >
        <Form form={formData} onFinish={onFinish}>
          <div>
            <Tooltip
              placement="top"
              title="For now it is only possible to enter Google Meet URL. E.g. https://meet.google.com/my-url-example"
            >
              <Form.Item
                name="url-meeting"
                rules={[
                  { required: true, message: 'Please enter a meeting URL.' }
                ]}
              >
                <Input placeholder="URL Meeting" />
              </Form.Item>
            </Tooltip>
            <Form.Item
              name="meeting-name"
              rules={[
                { required: true, message: 'Please enter a meeting name.' }
              ]}
            >
              <Input placeholder="Meeting name" />
            </Form.Item>
            <Form.List name="participants">
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field) => {
                    return (
                      <Form.Item
                        required={false}
                        key={field.key}
                        label="Participant:"
                      >
                        <Form.Item
                          name={field.name}
                          key={field.key}
                          isListField
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: false,
                              message:
                                "Please input participants's email or delete this field."
                            },
                            {
                              type: 'email',
                              required: true,
                              whitespace: false,
                              message: 'The input is not valid email!'
                            }
                          ]}
                          noStyle
                        >
                          <Input
                            placeholder="Participant email"
                            style={{ width: '60%' }}
                          />
                        </Form.Item>
                        <MinusCircleOutlined
                          onClick={() => remove(field.name)}
                          style={{
                            position: 'relative',
                            top: '4px',
                            margin: '0 8px',
                            color: '#999',
                            fontSize: '24px',
                            cursor: 'pointer',
                            transition: 'all 0.3s'
                          }}
                        />
                      </Form.Item>
                    )
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      style={{ width: '60%' }}
                      icon={<PlusOutlined />}
                    >
                      Add participant
                    </Button>
                    <Form.ErrorList errors={errors} />
                  </Form.Item>
                </>
              )}
            </Form.List>
            <Form.Item
              rules={[{ required: true, message: 'Please enter a date.' }]}
              name={'meeting-date'}
              // initialValue={
              //   dateText
              //     ? dayjs(dayjs(dateText).format('YYYY-MM-DD'), 'YYYY-MM-DD')
              //     : undefined
              // }
            >
              <DatePicker
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                // disabledTime={disabledDateTime}
                showSecond={false}
                showTime={{
                  defaultValue: dayjs('00:00', 'HH:mm'),
                  format: 'HH:mm'
                }}
                minDate={
                  dateText
                    ? dayjs(dayjs(dateText).format('YYYY-MM-DD'), 'YYYY-MM-DD')
                    : undefined
                }
                maxDate={
                  dateText
                    ? dayjs(dayjs(dateText).format('YYYY-MM-DD'), 'YYYY-MM-DD')
                    : undefined
                }
              />
            </Form.Item>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: '1.5rem'
            }}
          >
            <Button
              type="default"
              htmlType="button"
              style={{ width: '30%', marginRight: '10px' }}
              onClick={handleCancel}
            >
              CANCEL
            </Button>
            <Button type="primary" htmlType="submit" style={{ width: '30%' }}>
              SAVE
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default CreateManualMeetingForm
