import React, { useContext, useEffect, useState } from 'react'
import TemplatesDataService from '../../services/templates'
import { toast } from 'react-toastify'
import { Button, Table, TableProps, Tabs, TabsProps } from 'antd'
import edit from '../../assets/icons/edit.svg'
import { useNavigate } from 'react-router-dom'
import { steps } from '../../components/walkthroughs/templates'
import Joyride, { ACTIONS, CallBackProps, EVENTS } from 'react-joyride'
import JamyContext from '../../context/jamyContext'
import { EyeOutlined } from '@ant-design/icons'
import UserDataService from '../../services/users'
import { validateWalks } from '../../utils/validateWalks'

const TemlaplatesContainer: React.FC = () => {
  const templatesDataService = new TemplatesDataService()
  const { user, getUser } = useContext(JamyContext)
  const [loading, setLoading] = useState(true)
  const [page, setPage] = useState('1')
  const [tabSelected, setTabSelected] = useState<string>('1')
  const navigate = useNavigate()
  const [templates, setTemplates] = useState<Templates.ResponseTemplates>()
  const [runWalk, setRunWalk] = useState(false)
  const [stepIndex] = useState(1)
  const userDataService = new UserDataService()

  useEffect(() => {
    templatesDataService
      .getTemplatesByPage(page)
      .then((response) => {
        setTemplates(response.data)
        setLoading(false)
      })
      .catch((error) => {
        toast.error('Error getting templates', { theme: 'colored' })
        console.error(error)
      })
  }, [page])

  useEffect(() => {
    if (user) {
      !user.walkthroughs.templates_walkthrough && setRunWalk(true)
    }
  }, [user])

  // useEffect(() => {
  //   if (user) {
  //     userDataService
  //       .updateUserWalkthroughs(
  //         {
  //           templates_walkthrough: false
  //         },
  //         user ? user.id : ''
  //       )
  //       .then((data) => {
  //         console.log('User updated', data)
  //       })
  //       .catch((e) => {
  //         console.error('Error updating user templates_walkthrough value', e)
  //       })
  //   }
  // }, [user])

  const onChangePage = (page: number) => {
    setPage(page.toString())
  }

  const onChange = (key: string) => {
    setTabSelected(key)
  }

  const columns: TableProps<Templates.Results>['columns'] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      responsive: ['md']
    },
    {
      title: 'Options',
      dataIndex: 'id',
      key: 'id',
      render: (_, record: any) => {
        const isTheUserAnEditor = record.editors.some(
          (el: any) => el.id === user?.id
        )
        return (
          <div style={{ display: 'flex', justifyContent: 'space-around' }}>
            {isTheUserAnEditor ? (
              <img
                src={edit}
                onClick={() => navigate(`/templates/edit/${record.id}`)}
                alt="Edit"
                style={{ cursor: 'pointer' }}
              />
            ) : null}
            {isTheUserAnEditor ? null : (
              <EyeOutlined
                onClick={() => navigate(`/templates/edit/${record.id}`)}
                style={{ fontSize: '1rem', cursor: 'pointer' }}
              />
            )}
          </div>
        )
      }
    }
  ]

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'My templates',
      children: (
        <Table
          columns={columns}
          dataSource={
            templates
              ? templates.results.filter(
                  (x) => x.public === (tabSelected === '1' ? false : true)
                )
              : []
          }
          loading={loading}
          rowKey="id"
          pagination={{
            total: templates
              ? templates.results.filter(
                  (x) => x.organization === user?.organization
                ).length
              : 0,
            defaultCurrent: 1,
            defaultPageSize: 10,
            onChange: onChangePage
          }}
        />
      )
    }
    // {
    //   key: '2',
    //   label: 'Public',
    //   children: (
    //     <Table
    //       columns={columns}
    //       dataSource={
    //         templates
    //           ? templates.results.filter(
    //               (x) => x.public === (tabSelected === '2' ? true : false)
    //             )
    //           : []
    //       }
    //       loading={loading}
    //       rowKey="id"
    //       pagination={{
    //         total: templates
    //           ? templates.results.filter(
    //               (x) => x.public === (tabSelected === '2' ? true : false)
    //             ).length
    //           : 0,
    //         defaultCurrent: 1,
    //         defaultPageSize: 10,
    //         onChange: onChangePage
    //       }}
    //     />
    //   )
    // }
  ]

  const updateUserWalkValue = () => {
    userDataService
      .updateUserWalkthroughs(
        {
          templates_walkthrough: true
        },
        user ? user.id : ''
      )
      .then((data) => {
        console.log('User updated', data)
        getUser()
      })
      .catch((e) => {
        console.error('Error updating user templates_walkthrough value', e)
      })
  }

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { type, action } = data

    if (action === ACTIONS.CLOSE) {
      localStorage.setItem('fourth-walk-completed', 'true')
      const isAllWalksCompleted = validateWalks()
      if (isAllWalksCompleted) {
        updateUserWalkValue()
      }
      navigate('create')
    } else if (
      [EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type as any)
    ) {
      updateUserWalkValue()
      setRunWalk(false)
    }
  }

  return (
    <div>
      <Joyride
        run={runWalk}
        steps={steps}
        hideCloseButton
        styles={{ options: { primaryColor: '#E0AA25' } }}
        disableCloseOnEsc
        disableOverlayClose
        stepIndex={stepIndex}
        callback={handleJoyrideCallback}
      />
      <div
        style={{
          marginTop: '10px',
          marginBottom: '20px',
          display: 'flex',
          justifyContent: 'end'
        }}
      >
        <Button
          className="template-button-title"
          type="primary"
          onClick={() => navigate('create')}
        >
          CREATE TEMPLATE
        </Button>
      </div>
      <div
        style={{
          backgroundColor: 'white',
          padding: '20px',
          borderRadius: '10px'
        }}
      >
        <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
      </div>
    </div>
  )
}

export default TemlaplatesContainer
