import React from 'react'
import HelmetComponent from '../../../components/helmet'
import { LayoutMain } from '../../../layout'
import CreateComponentContainer from '../../../containers/componentsContainer/createComponentContainer'

export const CreateComponentPage: React.FC = () => {
  return (
    <LayoutMain title={'CREATE COMPONENT'}>
      <HelmetComponent title="Create Component"></HelmetComponent>
      <CreateComponentContainer />
    </LayoutMain>
  )
}

export default CreateComponentPage
