import React from 'react'
import { Route, Routes } from 'react-router-dom'

import { ProtectedRoute, PublicRoute } from '../hooks/useAuth'
import ForgetPasswordPage from './authentication/forgetPassword'
import LoginPage from './authentication/login'
import RegistrationPage from './authentication/registration'
import ResetPasswordPage from './authentication/resetPassword'
import { BotsPage } from './bots'
import CreateBotPage from './bots/create'
import EditBotPage from './bots/edit'
import DashboardPage from './dashboard'
import { OrganizationPage } from './organization'
import { ProfilePage } from './profile'
import { UsersPage } from './users'
import MeetingsPermissionsPage from './bots/meetings'
import ChatPage from './bots/chat'
import InvitationPage from './authentication/invitation'
import MeetingDetailPage from './meetings/details'
import { OnboardingPage } from './onboarding'
import { ServicesPage } from './services'
import CalendarPage from './calendar'
import ByDayPage from './calendar/byDay'
import { PricingPage } from './pricing'
import { StripePage } from './stripe'
import { IntegrationsPage } from './integrations'
import { ReportCreatorPage } from './reportCreator'
import { TemplatesPage } from './templates'
import ComponentsPage from './components'
import CreateComponentPage from './components/create'
import CreateTemplatePage from './templates/create'
import {FoldersPage} from './folders'
import {FoldersDetailsPage} from './folders/details'
import TestsComponentPage from './components/tests'

export const Main: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PublicRoute>
            <RegistrationPage />
          </PublicRoute>
        }
      />
      <Route
        path="/login"
        element={
          <PublicRoute>
            <LoginPage />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <PublicRoute>
            <RegistrationPage />
          </PublicRoute>
        }
      />
      <Route
        path="/signup/invitations/:id_invitation/:email"
        element={
          <PublicRoute>
            <InvitationPage />
          </PublicRoute>
        }
      />
      <Route
        path="/forgetPassword"
        element={
          <PublicRoute>
            <ForgetPasswordPage />
          </PublicRoute>
        }
      />
      <Route
        path="/resetPassword"
        element={
          <PublicRoute>
            <ResetPasswordPage />
          </PublicRoute>
        }
      >
        <Route
          path=":token?"
          element={
            <PublicRoute>
              <ResetPasswordPage />
            </PublicRoute>
          }
        />
      </Route>
      <Route
        path="/dashboard"
        element={
          <ProtectedRoute>
            <DashboardPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/organization"
        element={
          <ProtectedRoute>
            <OrganizationPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/profile/:code"
        element={
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/users"
        element={
          <ProtectedRoute>
            <UsersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bots"
        element={
          <ProtectedRoute>
            <BotsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bots/create-bot/:bot_type"
        element={
          <ProtectedRoute>
            <CreateBotPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bots/detail-bot/:idBot"
        element={
          <ProtectedRoute>
            <EditBotPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/bots/meetPermissions"
        element={
          <ProtectedRoute>
            <MeetingsPermissionsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/chat/:idBot"
        element={
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/meetChat/:meeting_bot_id/:idBot"
        element={
          <ProtectedRoute>
            <ChatPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/meetings/details/:meeting_id"
        element={<MeetingDetailPage />}
      />
      <Route
        path="/welcome"
        element={
          <ProtectedRoute>
            <OnboardingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/welcome/:userId"
        element={
          <ProtectedRoute>
            <OnboardingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/services"
        element={
          <ProtectedRoute>
            <ServicesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/calendar"
        element={
          <ProtectedRoute>
            <CalendarPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/calendar/day/:date"
        element={
          <ProtectedRoute>
            <ByDayPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pricing"
        element={
          <ProtectedRoute>
            <PricingPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/stripe"
        element={
          <ProtectedRoute>
            <StripePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/stripe/:update"
        element={
          <ProtectedRoute>
            <StripePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/integrations"
        element={
          <ProtectedRoute>
            <IntegrationsPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/report-creator"
        element={
          <ProtectedRoute>
            <ReportCreatorPage />
          </ProtectedRoute>
        }
      />

      <Route
        path="/templates"
        element={
          <ProtectedRoute>
            <TemplatesPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/components"
        element={
          <ProtectedRoute>
            <ComponentsPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/components/create"
        element={
          <ProtectedRoute>
            <CreateComponentPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/components/create/:id"
        element={
          <ProtectedRoute>
            <CreateComponentPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/components/tests/:id"
        element={
          <ProtectedRoute>
            <TestsComponentPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/templates/create"
        element={
          <ProtectedRoute>
            <CreateTemplatePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/templates/edit/:id"
        element={
          <ProtectedRoute>
            <CreateTemplatePage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/folders"
        element={
          <ProtectedRoute>
            <FoldersPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/folders/details/:id"
        element={
          <ProtectedRoute>
            <FoldersDetailsPage />
          </ProtectedRoute>
        }
      />
    </Routes>
  )
}
