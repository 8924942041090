import React from 'react'
import HelmetComponent from '../../../components/helmet'
import { LayoutMain } from '../../../layout'
import TestsComponentContainer from '../../../containers/componentsContainer/testsComponentContainer'

const TestsComponentPage: React.FC = () => {
  return (
    <LayoutMain title={'TESTS COMPONENT'}>
      <HelmetComponent title="Tests Component"></HelmetComponent>
      <TestsComponentContainer />
    </LayoutMain>
  )
}

export default TestsComponentPage
