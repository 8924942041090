import { Col, Row } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'
import LefSideRegister from '../../components/leftSideRegister'
import FooterLinks from '../../components/footerLinks'
import Loading from '../../components/loading'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import InvitationForm from '../../components/forms/invitationForm'
import { useForm } from 'antd/es/form/Form'
import {
  ValidatePassword,
  getPercentagePassword
} from '../../utils/validatePassword'
import { toast } from 'react-toastify'
import InvitationsDataService from '../../services/invitations'
import MeetingsDataService from '../../services/meetings'
import MessageBox from '../../components/messageBox'

export const InvitationContainer: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [invitationForm] = useForm()
  const [percentagePassword, setPercentagePassword] = useState(0)
  const [disableButton, setDisableButton] = useState(true)
  const { id_invitation, email } = useParams()
  const invitationService = new InvitationsDataService()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [messageBox, setMessageBox] = useState<ReactNode>()
  const meetingDataService = new MeetingsDataService()
  const [redirect, setRedirect] = useState<string | null>()
  const onFinish = (data: Invitations.RegisterInvitation) => {
    setLoading(true)
    if (
      ValidatePassword(data.password) &&
      data.password === data.repeatPassword &&
      id_invitation &&
      email
    ) {
      data.invitation_id = id_invitation
      invitationService
        .sendInvitation(data)
        .then((response) => {
          toast.success('Registration success', { theme: 'colored' })
          setTimeout(() => {
            navigate(`/login${redirect ? `?redirect=${redirect}` : ''}`)
          }, 1000)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          toast.error(error.message, { theme: 'colored' })
          console.log(error)
        })
    } else {
      setLoading(false)
      toast.warning('The password does not meet the minimum requirements', {
        theme: 'colored'
      })
    }
  }

  const onPasswordChange = (password: string) => {
    setPercentagePassword(getPercentagePassword(password))
  }

  const onFieldsChange = () => {
    const fields = [
      'first_name',
      'last_name',
      'email',
      'organization_name',
      'password',
      'repeatPassword'
    ]

    fields.forEach((element) => {
      if (!invitationForm.getFieldValue(element)) {
        setDisableButton(true)
      } else {
        setDisableButton(false)
      }
    })
  }

  useEffect(() => {
    if (searchParams.get('email')) {
      setRedirect(searchParams.get('redirect'))
      meetingDataService
        .getMeetingBanner(searchParams.get('email'))
        .then((response) => {
          setMessageBox(createMessageBox(response.data))
        })
        .catch((e) => console.log(e))
    }
  }, [])

  const createMessageBox = (response: Meetings.BannerResponse) => {
    let orgUsers = ''
    for (let index = 0; index < response.org_users.length; index++) {
      orgUsers =
        orgUsers +
        `${
          index == 0
            ? ' '
            : index > 0 && index < response.org_users.length - 1
            ? ', '
            : ' and '
        }` +
        response.org_users[index]
    }

    return (
      <p style={{ margin: '0' }}>
        Join <strong> {orgUsers} </strong> in the{' '}
        <strong> {response.organization_name} </strong>
        team in Jamy. <strong>Joining is free!</strong>
      </p>
    )
  }

  return (
    <React.Fragment>
      <Row>
        <Col span={12}>
          <LefSideRegister />
          <FooterLinks></FooterLinks>
        </Col>
        <Col span={12}>
          {/* TODO: Remove MessageBox if not needed */}
          <MessageBox messageBox={messageBox} />
          {!loading && email && id_invitation ? (
            <InvitationForm
              email={email}
              form={invitationForm}
              onFieldsChange={onFieldsChange}
              onFinish={onFinish}
              onPasswordChange={onPasswordChange}
              disableButton={disableButton}
              percentage={percentagePassword}
            />
          ) : (
            <Loading />
          )}
        </Col>
      </Row>
    </React.Fragment>
  )
}
