import React from 'react'
import HelmetComponent from '../../../components/helmet'
import { LayoutMain } from '../../../layout'
import FolderDetailsContainer from '../../../containers/folderDetailsContainer'


export const FoldersDetailsPage: React.FC = () => {
  return (
    <LayoutMain title="FOLDERS">
      <HelmetComponent title="Folders"></HelmetComponent>
      <FolderDetailsContainer />
    </LayoutMain>
  )
}
