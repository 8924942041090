import React from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import ReportCreatorContainer from '../../containers/reportCreatorContainer'

export const ReportCreatorPage: React.FC = () => {
  return (
    <LayoutMain title={'REPORT CREATOR'}>
      <HelmetComponent title="Report Creator"></HelmetComponent>
      <ReportCreatorContainer />
    </LayoutMain>
  )
}
