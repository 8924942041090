import React from 'react'
import HelmetComponent from '../../components/helmet'

import { LayoutMain } from '../../layout'
import TemlaplatesContainer from '../../containers/templatesContainer'

export const TemplatesPage: React.FC = () => {
  return (
    <LayoutMain title={'TEMPLATES'}>
      <HelmetComponent title="Templates"></HelmetComponent>
      <TemlaplatesContainer />
    </LayoutMain>
  )
}
