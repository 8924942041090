import React, { lazy, Suspense } from 'react'
import HelmetComponent from '../../components/helmet'
import { LayoutMain } from '../../layout'
import Loading from '../../components/loading'

const FoldersContainer = lazy(() => import('../../containers/foldersContainer'))

export const FoldersPage: React.FC = () => {
  return (
    <LayoutMain title="FOLDERS">
      <HelmetComponent
        title="Folders"
      />
      <Suspense fallback={<Loading />}>
        <FoldersContainer />
      </Suspense>
    </LayoutMain>
  )
}
